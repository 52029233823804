import {CHANGE_PROFILE, LOGIN, LOGOUT} from "../actions/actionTypes";

export const initialState =  {user: null, isAuth: false, error: ""};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      if(action.error) {
        return {
          isAuth: false,
          user: null,
          error: action.error
        };
      }
      else if(action.user) {
        if (action.user.lastProfile === null && action.user.profiles.length) {
          action.user.lastProfile = action.user.profiles[0].id;
        }
        return {
          isAuth: true,
          user: action.user,
          error: ""
        };
      }
      return initialState;
    case LOGOUT:
      localStorage.removeItem("hideProfile");
      return {
        isAuth: false,
        user: null,
        error: ""
      }
    case CHANGE_PROFILE:
      return {
        isAuth: true,
        error: "",
        user: action.user
      }
    default:
      return state;
  }
};
