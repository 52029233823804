import { CounterWidget, PdfPrinter, SeriesValueWidget, SlidingPanel, SlidingPanelContent, SlidingPanelWidget, Collapsible, TargetChartWidget, BarChartWidget, PageBreak } from "@bge/global_solution_workbench_commons";
import { faLayerGroup, faPencilRuler, faUsers, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, { useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { Datasources, Endpoints } from "../../../config/api";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import UserContext from "../../../utils/context/user/userContext";
import { jobPostingCurrentYear } from "../../../utils/dates/datesUtils";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import { barsOptionsReverse, seriesOptions, targetOptions } from "../config/chartOptions";
import {filterYear } from "../config/dates";
import { emptyFilter } from "../config/widgets";
import { EI_1, timeseriesLegend} from "./config/widgtes";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png";

const EILocalEconomyDashboard = ({solutionId}) => {

    const componentRef = useRef();
    const userContext = useContext(UserContext);
    const locale = useContext(LanguageContext).locale;

    const handlePrint = useReactToPrint({
        documentTitle: "EconomyInsightLocalEconomyDashboard.pdf",
        content: () => componentRef.current
    })

    return (
    <div ref={componentRef}>
        <DashboardHeader
                title= "Local Economy"
                printer={<PdfPrinter printHandler={handlePrint} />}
                contextWidget = {
                    <SolutionContextWidget
                        endpoint={Endpoints.RLS_INFO}
                        datasource={Datasources.EMSI_API_UK}
                        profileId={userContext.user.lastProfile}
                        solutionId={solutionId}
                        filters={emptyFilter}
                    />
                }
        />
        <Row className="justify-content md-center">
            <Col xs={12} sm={6} xl={3} className="mb-2">
                <SlidingPanel slidingPanelName="employmentSlide">
                    < SlidingPanelContent>
                        <div>
                            <h3>Employment</h3>
                            <p>{EI_1.employment.tooltip}</p>
                        </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                        <CounterWidget
                            endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.employment.facets)}
                            datasource={Datasources.EMSI_API_UK}
                            filter={EI_1.employment.filter}
                            requestHandler={dataRequest}
                            title="Employment"
                            subtitle={`Total jobs in ${filterYear}`}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            icon={faUsersGear}
                            iconColor="shape-secondary"
                            locale={locale}
                        />
                    </SlidingPanelWidget>
                </SlidingPanel>
            </Col>
            <Col xs={12} sm={6} xl={3} className="mb-2">
                <SlidingPanel
                    slidingPanelName="unemploymentSlide">
                    <SlidingPanelContent>
                        <div>
                        <h3>Unemployment</h3>
                        <p>{EI_1.unemployment.tooltip}</p>
                        </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                        <CounterWidget
                            endpoint={buildFinalUrl(Endpoints.RATES, EI_1.unemployment.facets, EI_1.unemployment.queries)}
                            datasource={Datasources.EMSI_API_UK}
                            title="Unemployment"
                            subtitle={`Unemployment rate in ${filterYear}`}
                            requestHandler={dataRequest}
                            filter={EI_1.unemployment.filter}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            icon={faPencilRuler}
                            iconColor="shape-secondary"
                            suffix="%"
                            isPercentage={true}
                            decimalDigits={1}
                            locale={locale}
                        />
                    </SlidingPanelWidget>
                </SlidingPanel>
            </Col>
            <Col xs={12} sm={6} xl={3} className="mb-2">
                <SlidingPanel slidingPanelName="populationSlide">
                    <SlidingPanelContent>
                        <div>
                            <h3>Population</h3>
                            <p>{EI_1.population.tooltip}</p>
                        </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                        <CounterWidget
                            endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.population.facets)}
                            datasource={Datasources.EMSI_API_UK}
                            filter={EI_1.population.filter}
                            requestHandler={dataRequest}
                            title="Population"
                            subtitle={`Population in ${filterYear}`}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            icon={faUsers}
                            iconColor="shape-secondary"
                            locale={locale}
                        />
                    </SlidingPanelWidget>
                </SlidingPanel>
            </Col>
            <Col xs={12} sm={6} xl={3} className="mb-2">
                <SlidingPanel
                    slidingPanelName="recruitmentDemandSlide">
                    <SlidingPanelContent>
                        <div>
                        <h3>Recruitment demand</h3>
                        <p>{EI_1.recruitmentDemand.tooltip}</p>
                        </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                        <CounterWidget
                            endpoint={Endpoints.TOTALS}
                            datasource={Datasources.EMSI_API_UK}
                            requestHandler={dataRequest}
                            title="Recruitment demand"
                            subtitle={`12 month unique postings in ${filterYear}`}
                            filter={EI_1.recruitmentDemand.filter}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            icon={faLayerGroup}
                            iconColor="shape-secondary"
                            locale={locale}
                        />
                    </SlidingPanelWidget>
                </SlidingPanel>
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4">
                <Collapsible title="Recruitment demand">
                    <SlidingPanel
                        slidingPanelName="timeSeriesSlide">
                        <SlidingPanelContent>
                        <div>
                            <h1>Demand</h1>
                            <p>{EI_1.timeseries.tooltip}</p>
                        </div>
                        </SlidingPanelContent>
                        <SlidingPanelWidget>
                        <SeriesValueWidget
                            endpoint={Endpoints.TIMELINES}
                            datasource={Datasources.EMSI_API_UK}
                            requestHandler={dataRequest}
                            title="Recruitment demand"
                            subtitle={"Unique active postings per month in the past 12 months"}
                            startYear={jobPostingCurrentYear}
                            filter={EI_1.timeseries.filter}
                            additionalYears = {EI_1.timeseries.additionalYears}
                            legendLabels={timeseriesLegend}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            chartClassName="ct-series-a ct-double-octave"
                            options={seriesOptions}
                            showTitle={false}
                            locale={locale}
                            printWidget={true}
                        />
                        </SlidingPanelWidget>
                    </SlidingPanel>
                </Collapsible>
            </Col>
        </Row>
        <PageBreak logo={CompanyLogo} alt="Lightcast"/>
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4">
                <Collapsible title="Industry composition">
                    <SlidingPanel
                    slidingPanelName="industryCompositionSlide">
                        <SlidingPanelContent>
                        <div>
                            <h1>Industry composition</h1>
                            <p>{EI_1.industryComposition.tooltip}</p>
                        </div>
                        </SlidingPanelContent>
                        <SlidingPanelWidget>
                        <TargetChartWidget
                            endpoint={buildFinalUrl(Endpoints.TARGETS, EI_1.industryComposition.facets)}
                            datasource={Datasources.EMSI_API_UK}
                            requestHandler={dataRequest}
                            title="Industry composition"
                            subtitle={`Jobs in ${filterYear} and UK equivalent`}
                            filter={EI_1.industryComposition.filter}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            chartClassName="ct-square"
                            options={targetOptions}
                            showTitle={false}
                            locale={locale}
                        />
                        </SlidingPanelWidget>
                    </SlidingPanel>
                </Collapsible>
            </Col>
        </Row>
        <PageBreak logo={CompanyLogo} alt="Lightcast"/>
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4">
                <Collapsible title="Top 10 companies">
                    <SlidingPanel
                    slidingPanelName="top10CompaniesSlide">
                        <SlidingPanelContent>
                        <div>
                            <h1>Top 10 companies</h1>
                            <p>{EI_1.top10.company.tooltip}</p>
                        </div>
                        </SlidingPanelContent>
                        <SlidingPanelWidget>
                        <BarChartWidget
                            endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_1.top10.company.facets)}
                            datasource={Datasources.EMSI_API_UK}
                            requestHandler={dataRequest}
                            title="Top 10 companies"
                            subtitle={`Unique active postings in the last 12 months`}
                            filter={EI_1.top10.company.filter}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            chartClassName="ct-octave"
                            options={barsOptionsReverse}
                            locale = {locale}
                            showTitle={false}
                            printWidget={true}
                        />
                        </SlidingPanelWidget>
                    </SlidingPanel>
                </Collapsible>
            </Col>
        </Row>
        <PageBreak logo={CompanyLogo} alt="Lightcast"/>
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4">
            <Collapsible title="Top 10 occupations">
                <SlidingPanel
                slidingPanelName="top10OccupationsSlide">
                    <SlidingPanelContent>
                    <div>
                        <h1>Top 10 occupations</h1>
                        <p>{EI_1.top10.occupation.tooltip}</p>
                    </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                    <BarChartWidget
                        endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_1.top10.occupation.facets)}
                        datasource={Datasources.EMSI_API_UK}
                        requestHandler={dataRequest}
                        title="Top 10 occupations"
                        subtitle={`Unique active postings in the last 12 months`}
                        filter={EI_1.top10.occupation.filter}
                        profileId={userContext.user.lastProfile}
                        solutionId={solutionId}
                        chartClassName="ct-octave"
                        options={barsOptionsReverse}
                        locale = {locale}
                        showTitle={false}
                        printWidget={true}
                    />                
                    </SlidingPanelWidget>
                </SlidingPanel>
            </Collapsible>
            </Col>
        </Row>
    </div>
    )
}

export default EILocalEconomyDashboard;