import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import FormCheck from "react-bootstrap/FormCheck";
import { Link } from 'react-router-dom';

import { AppRoutes } from "../../../routes";
import BgImage from "../../../assets/img/illustrations/signin.svg";
import {loginRequest} from "../../../utils/request/api";

import UserContext from "../../../utils/context/user/userContext";
import ServerError from "../ServerError/ServerError";
import {langMap} from "./i18n";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import { errorMessages } from "../../../config/errors";

const Signin = () => {

  const lang = useContext(LanguageContext).lang;
  const userContext = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [unavailable, setUnavailable] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    const data = await loginRequest(username, password, setUnavailable, rememberMe);
    data.error = data.error ? errorMessages[data.error].EN : "";
    if(data){
      userContext.handleUserLogin(data);
    };
  };

  const onErrorDismiss = () => {
    const data =  {
      user: null,
      error: ""
    }
    userContext.handleUserLogin(data);
  }

  if (unavailable)
    return <ServerError onBackToHome={setUnavailable}/>
  else
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center">
              <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.backButton[lang]}
              </Card.Link>
            </p>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-gray-lc-300 p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0 text-secondary-lc">{langMap.topText[lang]}</h3>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="text-dark-lc">{langMap.usernameLabel[lang]}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} className="text-secondary-lc" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required placeholder="example@company.com" onChange={({ target }) => setUsername(target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="pswd" className="mb-4">
                        <Form.Label className="text-dark-lc">{langMap.passwordLabel[lang]}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                          </InputGroup.Text>
                          <Form.Control required autoComplete="pswd" type="password" placeholder="Password" onChange={({ target }) => setPassword(target.value)} />
                        </InputGroup>
                      </Form.Group>
                      {
                        userContext.error &&
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss()}>
                              {userContext.error}
                          </Alert>
                        </div>
                      }
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox" >
                          <FormCheck.Input id="defaultCheck5" defaultChecked={true} className="me-2" onClick={() => setRememberMe(prev => !prev)} />
                          <FormCheck.Label htmlFor="defaultCheck5" className="mb-0 text-dark-lc">{langMap.rememberButton[lang]}</FormCheck.Label>
                        </Form.Check>
                        {/* <Card.Link className="small text-end">{langMap.lostPswdButton[lang]}</Card.Link> */}
                      </div>
                    </Form.Group>
                    <Button variant="secondary-lc" type="submit" className="w-100">
                      {langMap.signInButton[lang]}
                    </Button>
                    <div className="w-100">
                      <Button variant="primary-lc" as={Link} to={AppRoutes.ForgotPassword.path} className="button-forgot-password w-50 mt-3">
                        Forgot Password?
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
};

export default Signin;