import React, { useContext} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { currentYear } from "../../dashboard/config/dates";
import { Link, useSearchParams } from 'react-router-dom';
import { AppRoutes } from "../../../routes";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color_full.png"
import UserContext from "../../../utils/context/user/userContext";
import { passcodeRequest } from "../../../utils/request/api";

const Presentation = () => {

  const userContext = useContext(UserContext);
  const [searchParams] = useSearchParams();

  const checkPasscode = async e => {
    const pc = searchParams.get("pc");
    if(pc) {
      const data = await passcodeRequest(pc);
      if (data.user){
        localStorage.setItem("hideProfile", "true");
        data.user.hideProfile = true;
        userContext.handleUserLogin(data);
      }
    }
  };

  return (
    <>
      <section className="section-header overflow-hidden pt-6 pt-lg-8 bg-primary-lc text-black-lc d-flex align-items-stretch well" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <FontAwesomeIcon icon={faChartPie} />
              </div>
              <h1 className="fw-bolder text-secondary-lc">Global Solutions Workbench</h1>
              <p className="text-muted fw-light text-dark-lc  mb-5 h5">Dashboards for LMI analytics</p>
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="secondary-lc" as={Link} to={AppRoutes.DashboardOverview.path} onClick={checkPasscode} className="text-white-lc me-0">
                  Explore<FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" />
                </Button>
              </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  <a href="https://lightcast.io" target="_blank" rel="noopener noreferrer">
                    <Image src={CompanyLogo} width={380} className="mb-3" alt="Emsi Burning Glasss Logo" />
                    <p className="text-muted text-gray-lc-600 font-small m-0">A Lightcast solution</p>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="footer py-5 bg-dark-lc text-white-lc fixed-bottom">
        <Container>
          {/* <Row>
            <Col xs={12} md={6}>
              <span className="fw-bolder text-primary brand-text"><FontAwesomeIcon icon={faChartPie} /> About</span>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
            </Col>
            <Col xs={12} md={6}>
              <span className="fw-bolder text-primary brand-text"><FontAwesomeIcon icon={faEnvelope} /> Contact us</span>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" /> */}
          <Row>
            <Col className="mb-md-2">
              {/* <Card.Link href="https://emsibg.com" target="_blank" className="d-flex justify-content-center">
                <Image src={CompanyLogoFooter} width={200} className="d-block mx-auto mb-3" alt="Emsi Burning Glass Logo" />
              </Card.Link> */}
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Lightcast 2021-<span className="current-year">{ currentYear } </span>. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Presentation;