import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/DropdownItem";
import React from "react";


const ProfileSelectorItem = ({name}) => {
    return (
        <div style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
            <span className="px-2">{name}</span>
        </div>
    );
}

const SidebarProfileSelector = ({user, handleChange, className}) => {

    const activeProfile = user.profiles.find(p => p.id === user.lastProfile);
    const classNameString = `profile-selector dropdown-basic-button ${className}`

    return (
        <DropdownButton className={classNameString} title={
            <ProfileSelectorItem
               name={activeProfile.name}
            />
            }
        drop="up" >
        {
            user.profiles.map( ({name, id}) => {
               return (
                <DropdownItem key={name} { ...(id === activeProfile.id ? {active: "active"} : {})}
                              as="button" onClick={ () => handleChange({...user, lastProfile: id})}
                >
                <ProfileSelectorItem
                    name={name}
                />      
                </DropdownItem>
               )
            })
        }           
        </DropdownButton>
    )

}

export default SidebarProfileSelector 