import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/DropdownItem";
import React, {useContext} from "react";
import { Languages } from "../../config/dashboards";
import LanguageContext from "../../utils/context/i18n/languageContext";

const LanguageSelectorItem = ({lang, flag}) => {
    return (
        <div>
            <img width="15" src={flag} alt={lang}></img>
            <span className="px-20">{lang}</span>
        </div>
    );

}

const LanguageSelector = ({className}) => {
    const langContext = useContext(LanguageContext);
    const classNameString = `lang-selector dropdown-basic-button ${className}`

    return (
        <DropdownButton className={classNameString} title={
            <LanguageSelectorItem
                lang={langContext.lang}
                flag={require(`../../assets/img/flags/${langContext.flag}`)}
            />
            }
        drop="up" >
        {
            Languages.map( ({lang, flag, locale}) => {
               return (
                <DropdownItem key={lang} { ...(langContext.lang === lang ? {active: "active"} : {})}
                              as="button" onClick={ () => langContext.changeLang({lang: lang, locale: locale, flag: flag})}
                >
                <LanguageSelectorItem
                    lang={lang}
                    flag={require(`../../assets/img/flags/${flag}`)}
                />      
                </DropdownItem>
               )
            })
        }           
        </DropdownButton>
    );
}

export default LanguageSelector