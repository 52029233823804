import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from 'react-router-dom';
import CompanyLogoFull from "../../../assets/img/Lighcast_RGB_Lockup_Color_full.png"
import ServerError from "../ServerError/ServerError";
import {langMap} from "./i18n";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import gifLoading from "../../../assets/gifs/Lightcast_LoadingGif_White.gif";
import EmailSentImage from "../../../assets/img/Email_sent.png";
import { Endpoints } from "../../../config/api";
import { postRequest } from "../../../utils/request/api";
import { AppRoutes } from "../../../routes";
import PasswordPopup from "../../../components/PasswordPopup/PasswordPopup";
import { ROOT_WEBAPP } from "../../../config/env";
import { errorMessages } from "../../../config/errors";

const ForgotPassword = () => {

  const lang = useContext(LanguageContext).lang;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [unavailable, setUnavailable] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");

  const onErrorDismiss = () => {
    setError("");
  }

  const handleSubmit = async e => {
    e.preventDefault();
   
    setSendingRequest(true);

    const res = await postRequest(Endpoints.RESET_PASSWORD, {username: username, password: password, check: confirmPassword, origin: ROOT_WEBAPP});
    if (res.ok) {
      setResponse(`An email containing instructions on how to confirm the password reset has been sent to ${username}`);
      setError("");
    }
    else {
      setResponse("");
      if (res.error){
        setError(res.error);
      }
    };          

    setSendingRequest(false);
  };

  if (unavailable)
    return <ServerError onBackToHome={setUnavailable}/>
  else
    return (
      <main>
        <section className="d-flex align-items-center mb-lg-5 background-signin">
          <Container>
            {
            sendingRequest ?
              <Col xs={12} className="d-flex align-items-center h-100 justify-content-center">
                <div className="text-center text-md-center mb-10 mt-7 md-0">
                  <div className="loading-logo-bg">
                    <Image className="loading-logo" src={gifLoading} />
                  </div>
                  <h3 className="mb-0 text-checking text-secondary-lc ">sending request...</h3>
                </div>
              </Col>
              :
              <>
                <p className="text-center">
                  <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                    <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> {langMap.backButton[lang]}
                  </Card.Link>
                </p>
                {
                  response ?
                  <Row className="justify-content-center form-bg-image">
                    <Col sm={12} className="d-flex align-items-center justify-content-center">
                      <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-1000">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                          <Image src={CompanyLogoFull} width={500} className="" alt="Lightcast Logo" />
                          <h4 className="mb-0 title-create-account">{langMap.topText[lang]}</h4>
                        </div>
                        <h2 className="mail-sent-title text-center">Thank You !</h2>
                        <hr className="hr-mail-sent"></hr>
                        <h6 className="text-center mail-sent-text mt-2 mb-4">
                          {response}
                        </h6>
                        <div className="text-center w-100 mb-5 mt-2">
                          <Image src={EmailSentImage} width={450} className="" alt="Lightcast Logo" />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Card.Link as={Link} to={AppRoutes.Presentation.path}>
                            <Button variant="primary" type="submit">
                                Back to homepage
                            </Button>
                          </Card.Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  :
                  <Row className="justify-content-center mb-6 form-bg-image">
                    <Col xs={12} className="login-field d-flex align-items-center justify-content-center">
                      <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-1000">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                            <Image src={CompanyLogoFull} width={450} className="" alt="Lightcast Logo" />
                            <h4 className="mb-0 title-reset-password">{langMap.topText[lang]}</h4>
                        </div>
                        <Form className="mt-4 input-spacing-login" onSubmit={handleSubmit}>
                          <Form.Group id="email" className="mb-4">
                            <Form.Label className="text-dark-lc">{langMap.usernameLabel[lang]}</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope} className="text-secondary-lc" />
                              </InputGroup.Text>
                              <Form.Control tabIndex="1" autoFocus required placeholder="example@company.com" onChange={({ target }) => setUsername(target.value)}/>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group>
                            <Form.Group id="pswd" className="mb-4  password-title">
                              <Form.Label className="text-dark-lc">{langMap.passwordLabel[lang]}</Form.Label>
                              <PasswordPopup/>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                                </InputGroup.Text>
                                <Form.Control tabIndex="2" required autoComplete="new-password" type="password" placeholder="Password" onChange={({ target }) => setPassword(target.value)} />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group id="check" className="mb-5  password-title">
                              <Form.Label className="text-dark-lc">{langMap.confirmPasswordLabel[lang]}</Form.Label>
                              <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                                </InputGroup.Text>
                                <Form.Control tabIndex="3" required autoComplete="new-password-check" type="password" placeholder="Confirm password" onChange={({ target }) => setConfirmPassword(target.value)} />
                              </InputGroup>
                            </Form.Group>
                            {
                              error &&
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss()}>
                                  {error.split("\n").map((err, i)=> <p key={"err" + i} className="error-label">{errorMessages[err][lang]}</p>)}
                                </Alert>
                              </div>
                            }
                          </Form.Group>
                          <Button tabIndex="4" variant="secondary-lc" type="submit" className="button-signin-login w-100">
                            {langMap.confirmButton[lang]}
                          </Button>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                }
            </>
            }
          </Container>
        </section>
      </main>
    );
};

export default ForgotPassword;