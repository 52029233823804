export const langMap = {
    signout: {
        "IT": "Esci",
        "EN": "Sign Out"
    },
    profile: {
        "IT": "Il mio profilo",
        "EN": "My Profile"
    },
    support: {
        "IT": "Supporto",
        "EN": "Support"
    }
}