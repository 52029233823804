import { faLayerGroup, faPencilRuler, faUsers, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { Datasources, Endpoints } from "../../../config/api";
import UserContext from "../../../utils/context/user/userContext";
import { adjustYear, prevMonthNum } from "../../../utils/dates/datesUtils";
import { buildCoreLMIFilter, buildJobPostingsFilter, updateCoreLmiYear, updateJobPostingYear } from "../../../utils/filters/filters";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import { barsOptionsReverse, seriesOptions, targetOptions } from "../config/chartOptions";
import { currentYear } from "../config/dates";
import { emptyFilter } from "../config/widgets";
import { allowedYears, EI_1, EI_2, timeseriesLegend } from "./config/widgtes";
import SelectDataMessageBanner from "../../../components/SelectDataMessageBanner";
import { BarChartWidget, Collapsible, CounterWidget, GeneralSelector, PageBreak, PdfPrinter, SeriesValueWidget, SlidingPanel, SlidingPanelContent, SlidingPanelWidget, TargetChartWidget } from "@bge/global_solution_workbench_commons";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png"

const BenchmarkAnalysisDashboard = ({solutionId}) => {

    const componentRef = useRef();
    const userContext = useContext(UserContext);
    const locale = useContext(LanguageContext).locale;

    const [year, setYear] = useState(currentYear);
    const [currentAreaInfo, setCurrentAreaInfo] = useState(null);

    const handlePrint = useReactToPrint(
        {
          documentTitle: "BenchmarkAnalysisDashboard.pdf",
          content: () => componentRef.current
        }
    )

    const employmentFilter = useMemo(() => updateCoreLmiYear(EI_2.employment.filter, "Jobs", year), [year]);
    const employmentBenchmark = useMemo(
        () => currentAreaInfo ? 
            buildCoreLMIFilter(
                updateCoreLmiYear(EI_2.employment.benchmark, "Jobs", year),
                "Area",[[currentAreaInfo.name, currentAreaInfo.code]]
            )
        : null,
        [currentAreaInfo, year]
    );

    const unemploymentFilter = useMemo(() => updateCoreLmiYear(EI_2.unemployment.filter, "Population", year, ".Q1") , [year])
    const unemploymentBenchmark = useMemo(
        () => currentAreaInfo ?
            buildCoreLMIFilter(
                updateCoreLmiYear(EI_2.unemployment.benchmark, "Population", year, ".Q1"),
                "Area", [[currentAreaInfo.name, currentAreaInfo.code]]
            ) 
        : null,
        [currentAreaInfo, year]
    );

    const populationFilter = useMemo(() => updateCoreLmiYear(EI_2.population.filter, "Population", year, ".Q1"), [year])
    const populationBenchmark = useMemo(
        () => currentAreaInfo ?
            buildCoreLMIFilter(
                updateCoreLmiYear(EI_2.population.benchmark, "Population", year, ".Q1"),
                "Area", [[currentAreaInfo.name, currentAreaInfo.code]]
            )
        : null,
        [currentAreaInfo, year]
    );

    const recruitmentDemandFilter = useMemo(() => updateJobPostingYear(EI_2.recruitmentDemand.filter, year === currentYear ? year-1 : year, false), [year]);
    const recruitmentDemandBenchmark = useMemo(
        () => currentAreaInfo ?
            buildJobPostingsFilter(
                updateJobPostingYear(EI_2.recruitmentDemand.benchmark, year === currentYear ? year-1 : year, false),
                "lau1", currentAreaInfo.code
            )
        : null,
        [currentAreaInfo, year]
    );

    const timeseriesFilter = useMemo(()=> updateJobPostingYear(EI_2.timeseries.filter, adjustYear(year, prevMonthNum)), [year]);
    const timeseriesBenchmark = useMemo(
        () => currentAreaInfo ?
            buildJobPostingsFilter(
                updateJobPostingYear(EI_2.timeseries.benchmark, adjustYear(year, prevMonthNum) ),
                "lau1", currentAreaInfo.code
        ) 
        : null,
        [currentAreaInfo, year]
    );

    const industryCompositionFilter = useMemo(() => updateCoreLmiYear(EI_2.industryComposition.filter, "Jobs", year) , [year])
    const industryCompositionBenchmark = useMemo(
        () => currentAreaInfo ?
            buildCoreLMIFilter(
                updateCoreLmiYear(EI_2.industryComposition.benchmark, "Jobs", year),
                "Area", [[currentAreaInfo.name, currentAreaInfo.code]]
            )
         : null,
        [currentAreaInfo, year]
    );

    const topCompaniesFilter = useMemo(() => updateJobPostingYear(EI_2.company.filter, adjustYear(year, prevMonthNum)), [year])
    const topCompaniesBenchmark = useMemo(
        () =>  currentAreaInfo ? 
            buildJobPostingsFilter(
                updateJobPostingYear(EI_2.company.benchmark, adjustYear(year, prevMonthNum)),
                "lau1", currentAreaInfo.code
        )
         : null,
        [currentAreaInfo, year]
    );

    const selectDataMessage = "Please select an Area to compare";

    return (
        <div ref={componentRef}>
            <DashboardHeader
                title = "Benchmark Analysis" 
                printer = {<PdfPrinter printHandler={handlePrint} />}
                contextWidget = {<SolutionContextWidget
                    endpoint={Endpoints.RLS_INFO}
                    datasource={Datasources.EMSI_API_UK}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    filter={emptyFilter}
                />}
            />
            <Row className="mt-1">
                <Col xs={12} xl={6}>
                    <Row>
                    <Col xl={12}> 
                        <GeneralSelector
                            current={year}
                            passedItems={allowedYears.map(year => {return {name: year}})}
                            clickHandler={(item)=> setYear(item.name)}
                            requestHandler={null}
                            title="Year"
                            classSelected="year-selector"
                        />
                    </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6}>
                    <Row>
                        <Col xl={12}>
                            <GeneralSelector
                                current={currentAreaInfo?.name}
                                passedItems={userContext.user.customFilters[EI_2.userAreasKey]}
                                clickHandler={setCurrentAreaInfo}
                                requestHandler={null}
                                title="Benchmark area"
                                classSelected="area-selector"
                                searchEnabled={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-md-left mt-4">
                <Col xs={12} xl={6} className="mb-4">
                    <Row >
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel slidingPanelName="employmentSlide">
                                <SlidingPanelContent>
                                    <div>
                                        <h3>Employment</h3>
                                        <p>{EI_1.employment.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.employment.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        filter={employmentFilter}
                                        title="Employment"
                                        subtitle={`Total jobs in ${year === currentYear ? year-1 : year}`}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faUsersGear}
                                        iconColor="shape-secondary"
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel slidingPanelName="unemploymentSlide">
                                <SlidingPanelContent>
                                    <div>
                                        <h3>Unemployment</h3>
                                        <p>{EI_1.unemployment.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={buildFinalUrl(Endpoints.RATES, EI_1.unemployment.facets, EI_1.unemployment.queries)}
                                        datasource={Datasources.EMSI_API_UK}
                                        title="Unemployment"
                                        subtitle={`Unemployment rate in ${year === currentYear ? year-1 : year}`}
                                        requestHandler={dataRequest}
                                        filter={unemploymentFilter}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faPencilRuler}
                                        iconColor="shape-secondary"
                                        suffix="%"
                                        isPercentage={true}
                                        decimalDigits={1}
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel slidingPanelName="populationSlide">
                                <SlidingPanelContent>
                                    <div>
                                        <h3>Population</h3>
                                        <p>{EI_1.population.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.population.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        filter={populationFilter}
                                        title="Population"
                                        subtitle={`Population in ${year === currentYear ? year-1 : year}`}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faUsers}
                                        iconColor="shape-secondary"
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel
                                slidingPanelName="recruitmentDemandSlide">
                                <SlidingPanelContent>
                                    <div>
                                        <h3>Recruitment demand</h3>
                                        <p>{EI_1.recruitmentDemand.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={Endpoints.TOTALS}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Recruitment demand"
                                        subtitle={`12 month unique postings in ${year === currentYear ? year-1 : year}`}
                                        filter={recruitmentDemandFilter}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faLayerGroup}
                                        iconColor="shape-secondary"
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6} className="mb-4">
                    {
                        !currentAreaInfo && <SelectDataMessageBanner
                            message = {selectDataMessage}
                        />
                    }
                    {
                    currentAreaInfo &&
                    <Row>
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel slidingPanelName="employmentSlide">
                                <SlidingPanelContent>
                                    <div>
                                        <h3>Employment</h3>
                                        <p>{EI_1.employment.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.employment.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        filter={employmentBenchmark}
                                        requestHandler={dataRequest}
                                        disableRls={true}
                                        title="Employment"
                                        subtitle={`Total jobs in ${year === currentYear ? year-1 : year}`}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faUsersGear}
                                        iconColor="shape-secondary"
                                        widgetClassName="benchmark"
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                        <Col xs={12} sm={6} xl={6} className="mb-2">
                            <SlidingPanel slidingPanelName="unemploymentSlide">
                                <SlidingPanelContent>
                                    <div>
                                    <h3>Unemployment</h3>
                                        <p>{EI_1.unemployment.tooltip}</p>
                                    </div>
                                </SlidingPanelContent>
                                <SlidingPanelWidget>
                                    <CounterWidget
                                        endpoint={buildFinalUrl(Endpoints.RATES, EI_1.unemployment.facets, EI_1.unemployment.queries)}
                                        datasource={Datasources.EMSI_API_UK}
                                        title="Unemployment"
                                        subtitle={`Unemployment rate in ${year === currentYear ? year-1 : year}`}
                                        filter={unemploymentBenchmark}
                                        requestHandler={dataRequest}
                                        disableRls={true}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        icon={faPencilRuler}
                                        iconColor="shape-secondary"
                                        suffix="%"
                                        isPercentage={true}
                                        decimalDigits={1}
                                        widgetClassName="benchmark"
                                        locale={locale}
                                    />
                                </SlidingPanelWidget>
                            </SlidingPanel>
                        </Col>
                     </Row>
                    }  
                    {
                    currentAreaInfo &&
                        <Row>
                            <Col xs={12} sm={6} xl={6} className="mb-2">
                                <SlidingPanel slidingPanelName="populationSlide">
                                    <SlidingPanelContent>
                                        <div>
                                            <h3>Population</h3>
                                            <p>{EI_1.population.tooltip}</p>
                                        </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                        <CounterWidget
                                            endpoint={buildFinalUrl(Endpoints.TOTALS, EI_1.population.facets)}
                                            datasource={Datasources.EMSI_API_UK}
                                            requestHandler={dataRequest}
                                            filter={populationBenchmark}
                                            disableRls={true}
                                            title="Population"
                                            subtitle={`Population in ${year === currentYear ? year-1 : year}`}
                                            profileId={userContext.user.lastProfile}
                                            solutionId={solutionId}
                                            icon={faUsers}
                                            iconColor="shape-secondary"
                                            widgetClassName="benchmark"
                                            locale={locale}
                                        />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                            </Col>
                            <Col xs={12} sm={6} xl={6} className="mb-2">
                                <SlidingPanel
                                    slidingPanelName="recruitmentDemandSlide">
                                    <SlidingPanelContent>
                                        <div>
                                            <h3>Recruitment demand</h3>
                                            <p>{EI_1.recruitmentDemand.tooltip}</p>
                                        </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                        <CounterWidget
                                            endpoint={Endpoints.TOTALS}
                                            datasource={Datasources.EMSI_API_UK}
                                            requestHandler={dataRequest}
                                            title="Recruitment demand"
                                            subtitle={`12 month unique postings in ${year === currentYear ? year-1 : year}`}
                                            filter={recruitmentDemandBenchmark}
                                            disableRls={true}
                                            profileId={userContext.user.lastProfile}
                                            solutionId={solutionId}
                                            icon={faLayerGroup}
                                            iconColor="shape-secondary"
                                            widgetClassName="benchmark"
                                            locale={locale}
                                        />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col className="mb-4">
                    <Collapsible title="Recruitment demand">
                        <Row>
                            <Col xs={12} xxl={6} className="mb-4">
                                <SlidingPanel
                                    slidingPanelName="timeSeriesSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Recruitment demand</h1>
                                        <p>{EI_1.timeseries.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <SeriesValueWidget
                                        endpoint={Endpoints.TIMELINES}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Demand"
                                        subtitle={"Unique active postings per month in the past 12 months"}
                                        startYear={adjustYear(year, prevMonthNum)}
                                        filter={timeseriesFilter}
                                        additionalYears = {[adjustYear(year-1, prevMonthNum)]}
                                        legendLabels={timeseriesLegend}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        chartClassName="ct-series-a ct-octave"
                                        options={seriesOptions}
                                        showTitle={false}
                                        locale={locale}
                                        printWidget={true}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                            </Col>
                            <Col xs={12} xxl={6} className="mb-4">
                                {
                                currentAreaInfo ?
                                <SlidingPanel
                                    slidingPanelName="timeSeriesSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Demand</h1>
                                        <p>{EI_1.timeseries.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <SeriesValueWidget
                                        endpoint={Endpoints.TIMELINES}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Demand"
                                        subtitle={`Unique active postings per month in the past 12 months`}
                                        filter={timeseriesBenchmark}
                                        disableRls={true}
                                        startYear={adjustYear(year, prevMonthNum)}
                                        additionalYears ={[adjustYear(year-1, prevMonthNum)]}
                                        legendLabels={timeseriesLegend}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        widgetClassName="benchmark benchmark-right"
                                        chartClassName="ct-series-a ct-octave"
                                        options={seriesOptions}
                                        showTitle={false}
                                        localle={locale}
                                        printWidget={true}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                                :
                                <SelectDataMessageBanner
                                    message = {selectDataMessage}
                                />
                                }
                            </Col>
                        </Row> 
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col className="mb-4">
                    <Collapsible title="Industry composition">
                        <Row>
                            <Col xs={12} xxl={6} className="mb-4">
                                <SlidingPanel slidingPanelName="industryCompositionSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Industry composition</h1>
                                        <p>{EI_1.industryComposition.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <TargetChartWidget
                                        endpoint={buildFinalUrl(Endpoints.TARGETS, EI_1.industryComposition.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Industry composition"
                                        subtitle={`Jobs in ${year === currentYear ? year-1 : year} and UK equivalent`}
                                        filter={industryCompositionFilter}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        chartClassName="half-width ct-square"
                                        options={targetOptions}
                                        showTitle={false}
                                        locale={locale}
                                        targetCircle={false}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                            </Col>
                            <Col xs={12} xxl={6} className="mb-4">
                                { 
                                currentAreaInfo ?
                                <SlidingPanel slidingPanelName="industryCompositionSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Industry composition</h1>
                                        <p>{EI_1.industryComposition.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <TargetChartWidget
                                        endpoint={buildFinalUrl(Endpoints.TARGETS, EI_1.industryComposition.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Industry composition"
                                        subtitle={`Jobs in ${year === currentYear ? year-1 : year} and UK equivalent`}
                                        filter={industryCompositionBenchmark}
                                        disableRls={true}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        widgetClassName="benchmark"
                                        chartClassName="half-width ct-square"
                                        options={targetOptions}
                                        showTitle={false}
                                        locale={locale}
                                        targetCircle={false}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                                :
                                <SelectDataMessageBanner
                                    message = {selectDataMessage}
                                />
                                }
                            </Col>
                        </Row>
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col className="mb-4">
                    <Collapsible title="Top 10 companies">
                        <Row>
                            <Col xs={12} xxl={6} className="mb-4">
                                <SlidingPanel slidingPanelName="top10CompaniesSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Top 10 companies</h1>
                                        <p>{EI_1.top10.company.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <BarChartWidget
                                        endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_1.top10.company.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Top 10 companies"
                                        subtitle={`Unique active postings in the last 12 months`}
                                        filter={topCompaniesFilter}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        chartClassName="half-width ct-golden-section"
                                        options={barsOptionsReverse}
                                        locale={locale}
                                        showTitle={false}
                                        printWidget={true}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                            </Col>
                            <Col xs={12} xxl={6} className="mb-4">
                                {
                                currentAreaInfo ?
                                <SlidingPanel slidingPanelName="top10CompaniesSlide">
                                    <SlidingPanelContent>
                                    <div>
                                        <h1>Top 10 companies</h1>
                                        <p>{EI_1.top10.company.tooltip}</p>
                                    </div>
                                    </SlidingPanelContent>
                                    <SlidingPanelWidget>
                                    <BarChartWidget
                                        endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_1.top10.company.facets)}
                                        datasource={Datasources.EMSI_API_UK}
                                        requestHandler={dataRequest}
                                        title="Top 10 companies"
                                        subtitle={`Unique active postings in the last 12 months`}
                                        filter={topCompaniesBenchmark}
                                        disableRls={true}
                                        profileId={userContext.user.lastProfile}
                                        solutionId={solutionId}
                                        widgetClassName="benchmark benchmark-top-companies"
                                        chartClassName="half-width ct-golden-section"
                                        options={barsOptionsReverse}
                                        locale={locale}
                                        showTitle={false}
                                        printWidget={true}
                                    />
                                    </SlidingPanelWidget>
                                </SlidingPanel>
                                :
                                <SelectDataMessageBanner
                                    message = {selectDataMessage}
                                />
                                }
                            </Col>
                        </Row>
                    </Collapsible>
                </Col>
            </Row>
        </div>
    )
}

export default BenchmarkAnalysisDashboard;