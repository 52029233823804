import { currentYear } from "../../pages/dashboard/config/dates";

function buildCoreLMIFilter(startFilter, constraintName, constraints){
    let finalFilter = {...startFilter};
    const newConstraint =  {"dimensionName": constraintName, "map": {} };
    for (const [k, v] of constraints)
      newConstraint["map"][k] =  v;
  
    if(finalFilter["constraints"])
      finalFilter["constraints"] = [...startFilter["constraints"], newConstraint];
    else 
      finalFilter["constraints"] = [newConstraint];
    
    return finalFilter;
  }
  
function buildJobPostingsFilter(startfilter, name, v){
    let finalFilter = {...startfilter};
    finalFilter["filter"][name] = v;
    return finalFilter;
}

function updateCoreLmiYear(startfilter, prefix, newYear, suffix="") {

  if(newYear === currentYear)
    newYear--;

  const newMetricName = `${prefix}.${newYear}${suffix}`;

  let finalFilter = {...startfilter};

  for(let metric of finalFilter.metrics){
    if (metric.name.startsWith(prefix)){
      metric.name = newMetricName;
    };
  };

  if(finalFilter.sortBy){
    for(let metric of finalFilter.sortBy){
      if(metric.name.startsWith(prefix)){
        metric.name = newMetricName;
      }
    };
  };

  return finalFilter;
};

function updateJobPostingYear(startFilter, newYear, rolling=true) {

  let finalFilter = {...startFilter}

  finalFilter.filter.when.start = `${rolling ? newYear-1 : newYear}-${startFilter.filter.when.start.substring(5)}`;
  finalFilter.filter.when.end = `${newYear}-${startFilter.filter.when.end.substring(5)}`;

  return finalFilter;
}

export {buildCoreLMIFilter, buildJobPostingsFilter, updateCoreLmiYear, updateJobPostingYear};