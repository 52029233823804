import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { setupTableauEmbedding } from "../../../utils/tableau/TableauEmbed";

const GreenShareEuropeDashboard  = () => {

    useEffect(() => {
        setupTableauEmbedding('viz1662200142038');
    });

    return (
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4 d-none d-sm-block">
                <div className="tableauPlaceholder" id="viz1662200142038">
                    <noscript>
                        <a href='#'>
                            <img alt='Dashboard' src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;AR&#47;AR_GreenDashboard_EU&#47;Greenskill&#47;1_rss.png" style={{border: 'none'}} />
                        </a>
                    </noscript>
                    <object className='tableauViz'>
                        <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F'/>
                        <param name='embed_code_version' value='3' />
                        <param name='site_root' value='' />
                        <param name='name' value="AR_GreenDashboard_EU&#47;Greenskill" />
                        <param name='tabs' value='no' />
                        <param name='toolbar' value='yes' />
                        <param name='static_image' value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;AR&#47;AR_GreenDashboard_EU&#47;Greenskill&#47;1.png" />
                        <param name='animate_transition' value='yes' />
                        <param name='display_static_image' value='yes' />
                        <param name='display_spinner' value='yes' />
                        <param name='display_overlay' value='yes' />
                        <param name='display_count' value='yes' />
                    </object>
                </div>
            </Col>
        </Row>
    )

}


export default GreenShareEuropeDashboard;
            