import React, {useContext, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import UserContext from "../../utils/context/user/userContext";
import { updateResourceRequest } from "../../utils/request/api";
import { Endpoints } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt, faUserGear, faUserCircle, faEarthEurope, faBuilding, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import PasswordPopup from "../../components/PasswordPopup/PasswordPopup";
import LanguageContext from "../../utils/context/i18n/languageContext";
import { errorMessages } from "../../config/errors";

const UserProfile = () => {

    const lang = useContext(LanguageContext).lang;
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const loginDate = new Date(user.lastLogin);
    const [userInfo, setUserInfo] = useState({
        username: user.username, email: user.email, firstName: user.firstName, lastName: user.lastName, jobTitle: user.jobTitle, organizationName: user.organizationName,
        homeCountry: user.homeCountry, concurrentAccess: user.concurrentAccess, enabled: user.enabled, currentPassword: "", newPassword: "", check: ""
    });
    const [error, setError] = useState("");
    const [response, setResponse] = useState("");

    const onDismiss = () => {
        setError("");
        setResponse("");
    }

    const handleUpdate = async() => {
        const res = await updateResourceRequest(Endpoints.USER_PROFILE+user.id, userInfo);
        if (!res.response)
            setError(res.error)
        else{
            const data = {user: res.response, error: res.error}
            userContext.handleUserLogin(data);
            setResponse("You account has been updated.")
        }
    }

    return (
        <section className="overflow-hidden pt-2 align-items-stretch userprofile">
            <Container fluid>
                <Row className="mt-3 text-center">
                    <div className="hiuser">
                        <h2 className="text-secondary-lc">Hello, {user.username}</h2>
                    </div>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} className="mb-5 text-center">
                        <span className="last-login-span">{`Your last login has been on: `}<b>{loginDate.toLocaleString()}</b></span>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} className="columns-input mb-4 justify-content-center" >
                        <h4 className="title-category-profile">Info</h4>
                        <hr className="hr-category-profile"></hr>
                        <FontAwesomeIcon className="text-secondary-lc icon-about-you" icon={faUserGear} />
                    </Col>
                    <Row className="columns-input mb-6">
                        <Col sm={2}></Col>
                        <Col sm={4} className="column-input">
                            <Form.Group id="firstName" className="mb-4">
                                <Form.Label><span>Change first name</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUserCircle} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="1"  autoFocus required placeholder={userInfo.firstName} onChange={({ target }) => setUserInfo((prev) => ({...prev, firstName: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="homeCountry" className="mb-4">
                                <Form.Label><span>Change country</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEarthEurope} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="3" autoFocus required placeholder={userInfo.homeCountry} onChange={({ target }) => setUserInfo((prev) => ({...prev, homeCountry: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="organizationName" className="mb-4">
                                <Form.Label><span>Change organization name</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faBuilding} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="5" autoFocus required placeholder={userInfo.organizationName} onChange={({ target }) => setUserInfo((prev) => ({...prev, organizationName: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4} className="column-input">
                            <Form.Group id="lastName" className="mb-4">
                                <Form.Label><span>Change last name</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUserCircle} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="2" autoFocus required placeholder={userInfo.lastName} onChange={({ target }) => setUserInfo((prev) => ({...prev, lastName: target.value}))}/>
                                </InputGroup>
                            </Form.Group> 
                            <Form.Group id="jobTitle" className="mb-4">
                                <Form.Label><span>Change job title</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faBriefcase} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="4" autoFocus required placeholder={userInfo.jobTitle} onChange={({ target }) => setUserInfo((prev) => ({...prev, jobTitle: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    <Col xs={12} className="columns-input mb-4 justify-content-center" >
                        <h4 className="title-category-profile">Credentials</h4>
                        <hr className="hr-category-profile"></hr>
                        <FontAwesomeIcon className="text-secondary-lc icon-about-you" icon={faUnlockAlt} />
                    </Col>
                    <Row className="columns-input mb-6">
                        <Col sm={2}></Col>
                        <Col sm={4} className="column-input">
                            <Form.Group id="username" className="mb-4">
                                <Form.Label><span>Change Username</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEnvelope} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="6" autoFocus required placeholder={userInfo.username} onChange={({ target }) => setUserInfo((prev) => ({...prev, username: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="new-pswd" className="mb-4 password-title">
                                <Form.Label><span>Change Password</span></Form.Label>
                                <PasswordPopup />
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon className="profile-input-icons" icon={faUnlockAlt} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="8" required autoComplete="new-password" type="password" placeholder="New Password" onChange={({ target }) => setUserInfo((prev) => ({...prev, newPassword: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={4} className="column-input">
                            <Form.Group id="email" className="mb-4">
                                <Form.Label><span>Change Email Address</span></Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faEnvelope} />
                                    </InputGroup.Text>
                                    <Form.Control tabIndex="7" autoFocus required placeholder={userInfo.email} onChange={({ target }) => setUserInfo((prev) => ({...prev, email: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group id="check" className="mb-4">
                                <Form.Label><span>Confirm new Password</span></Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon className="profile-input-icons" icon={faUnlockAlt} />
                                </InputGroup.Text>
                                <Form.Control tabIndex="9" required  autoComplete="new-password-check" type="password" placeholder="Confirm New Password" onChange={({ target }) => setUserInfo((prev) => ({...prev, check: target.value}))}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    <Col xs={8} xl={4}>
                        <Form.Group id="old-pswd" className="mb-6 mt-2">
                            <Form.Label><span>Current Password (Required)</span></Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon className="text-secondary-lc" icon={faUnlockAlt} />
                                </InputGroup.Text>
                                <Form.Control tabIndex="10" required autoComplete="old-password" type="password" placeholder="Current Password" onChange={({ target }) => setUserInfo((prev) => ({...prev, currentPassword: target.value}))}/>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                { 
                    error &&
                    <Row className="justify-content-center">
                        <Col xs={8} xl={4}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <Alert variant="danger" dismissible={true} onClose={() => onDismiss()}>
                                    {error.split(", ").map( (e, i) => <p key={`error-${i}`}>{errorMessages[e][lang]}</p>)}
                                </Alert>
                            </div>
                        </Col>
                    </Row>
                }
                {
                    response &&
                    <Row className="justify-content-center">
                        <Col xs={8} xl={4}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <Alert variant="success" dismissible={true} onClose={() => onDismiss()}>
                                    {response}
                                </Alert>
                            </div>
                        </Col>
                    </Row>
                }
                <Row className="justify-content-center mt-2">
                    <Col xs={8} xl={4}>
                        <Button variant="secondary-lc" className="w-100" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default UserProfile;