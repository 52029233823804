function setYAxisOffset(width) {
    if (width<=576) {
        return 0
    } else if (width<=768) {
        return 220
    } else if (width<=992) {
        return 250
    } else if (width<=1200) {
        return 300
    // deepcode ignore DuplicateIfBody: <please specify a reason of ignoring this>
    } else {
        return 300
    }
}

const offset = setYAxisOffset(window.innerWidth);

export const targetOptions = {
    axisY: {
      offset: offset,
    },
    axisX: {
      display: true,
      onlyInteger: true,
      offset: 0
    },
    scales: {
      xAxes: [{
          ticks: {
              display: false
          }
      }]
    },
    chartPadding: {
      top: 0,
      right: 20,
      bottom: 0,
      left: 10
    }  
  }

  function getPaddingLeft(){
    return ((window.screen.width) / 21) 
  }

export const seriesOptions = {
    chartPadding: {
      top: 20,
      right: 0,
      bottom: 10,
      left: getPaddingLeft()
    }
    // labelOffset: {
    //   x: -30,
    //   y: 0
    // },
    // labelDirection: 'explode'
  };

  export const barsOptions = {
    axisY: {
      offset: offset,
    },
    axisX: {
      display: true,
      onlyInteger: true,
      offset: 0
    },
    scales: {
      xAxes: [{
          ticks: {
              display: false
          }
      }]
    },
    chartPadding: {
      top: 0,
      right: 20,
      bottom: 0,
      left: 10
    },
    reverseData:false
  }

export const barsOptionsReverse = {
    ...barsOptions,
    reverseData:true
  }

export const dotsOptions = {
    axisY: {
      offset: offset,
    },
    axisX: {
      onlyInteger: true,
    },
    chartPadding: {
      top: 0,
      right: 20,
      bottom: 0,
      left: 10
    }
  }
  
  