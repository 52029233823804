import { CHANGE_LANG } from "../actions/actionTypes";

export const initialState = {
    lang: "EN",
    locale: "en-GB",
    flag: "united-kingdom.svg"
}

export const LangReducer = (state=initialState, action) => {
    if(action.type === CHANGE_LANG)
        return {
            lang: action.lang,
            locale: action.locale,
            flag: action.flag
        };
    return state;
}