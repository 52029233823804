import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


const SelectDataMessageBanner = ({ message }) => {

    return(
        <Row className="d-flex align-items-center justify-content-xl-center h-100">
            <Col xl={12} className="d-flex align-items-center justify-content-xl-center px-xl-0">
                <p className={`selectDataAreaBanner d-inline`} >{message}</p>
            </Col>
        </Row>
    )
}

export default SelectDataMessageBanner;