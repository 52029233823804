import { TABLEAU_SCRIPT_SRC } from "../../config/tableau";

export const setupTableauEmbedding = (id) => {

    let tooltip = document.getElementsByClassName("chartist-tooltip");
    if(tooltip.length){
      document.querySelector(".chartist-tooltip").remove();
    }

    const divElement = document.getElementById(id);                    
    const vizElement = divElement.getElementsByTagName('object')[0];  

    // vizElement.style.minWidth='800px';wid
    // vizElement.style.maxWidth='100%';
    // vizElement.style.minHeight= '600px';
    // vizElement.style.maxHeight=(divElement.offsetWidth*0.75)+'px'; 

    // if  ( divElement.offsetWidth > 800 ) { 
    //     vizElement.style.width='1500px';
    //     vizElement.style.height='977px';
    // } 
    // else if ( divElement.offsetWidth > 500 ) { 
    //     vizElement.style.width='1100px';
    //     vizElement.style.height='877px';
    // } 
    // else {
    //     vizElement.style.width='100%';
    //     vizElement.style.height='727px';
    // }
    vizElement.style.width = (window.screen.availWidth * 0.80)+'px';
    vizElement.style.height = (window.screen.availHeight * 0.95)+'px';

    const scriptElement = document.createElement('script');
    scriptElement.src = TABLEAU_SCRIPT_SRC;
    vizElement.parentNode.insertBefore(scriptElement, vizElement);
}

                  
