import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { faAngleLeft, faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons"
import { useEffect } from "react";
import gifLoading from "../../../assets/gifs/Lightcast_LoadingGif_White.gif";
import { Endpoints } from "../../../config/api";
import { postRequest } from "../../../utils/request/api";
import { AppRoutes } from "../../../routes";

const ResetPassword = () => {

  const [params] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
      (async ()=> {
          const token = params.get("token");
          if(token) {
              const res = await postRequest(Endpoints.CONFIRM_RESET_PASSWORD, {token: token});
              if (res.ok) {
                  setError("");
                  setVerified(true);
              }
              else {
                  setVerified(false);
                  if(res.error)
                      setError(res.error);
                  else
                      navigate(AppRoutes.ServerError.path);
              }
          } 
          else
              setError("No registration code has been provided");
      })();
  },[params, navigate])

  return(
    <main>
        <section className="d-flex align-items-center mt-5 mt-lg-6 mb-lg-5">
            <Container>
                <p className="text-center">
                    <Card.Link as={Link} to={AppRoutes.Presentation.path} className="text-gray-lc-600">
                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
                    </Card.Link>
                </p>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      {
                        verified ?  
                        <>
                        <h1 className="mb-0 text-success text-secondary-lc">
                            Password reset successfully!
                        </h1>
                        <h4 className="secondary-text-success pt-3">
                            Now you can use your new credentials.<br/> Click on the button below to use them.
                        </h4>  
                        <FontAwesomeIcon icon={faCircleCheck} className="mt-6 icon-success" /> 
                        <Link as={Link} to={AppRoutes.DashboardOverview.path} className="d-flex justify-content-center align-items-center mt-6">
                            <Button variant="primary" type="submit" className="w-50">
                                Login
                            </Button>
                        </Link>
                        </> 
                        :
                        <>
                        {
                          error ? 
                          <Col xs={12} className="d-flex align-items-center justify-content-center">
                              <div className="text-center text-md-center mb-4 mt-md-0">
                                  <h3 className="mb-0 text-danger">{error}</h3>
                                  <FontAwesomeIcon icon={faCircleXmark} className="mt-6 icon-success" />
                              </div>
                          </Col>
                          :
                          <Col xs={12} className="d-flex align-items-center justify-content-center">
                              <div className="text-center text-md-center mb-4 mt-md-0">
                                  <div className="loading-logo-bg">
                                      <Image className="loading-logo" src={gifLoading} />
                                  </div>
                                  <h3 className="mb-0 text-danger">Checking token...</h3>
                              </div>
                          </Col>
                        }
                        </>
                        }
                    </div>
                </Col>  
            </Container>
        </section>
    </main>
  )
};

export default ResetPassword;