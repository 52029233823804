
import React, {useContext, useEffect } from "react"
import UserContext from "../../utils/context/user/userContext";
import { logginRequest } from "../../utils/request/api";


const AccessLogger = ({solutionId, children, publicAccess=false}) => {

    const userContext = useContext(UserContext);
    const auth = publicAccess || userContext.isAuth;
    const userId = publicAccess ? 0 : userContext.user?.id;
    const lastProfile = publicAccess ? 0 : userContext.user?.lastProfile;

    useEffect(()=> {
        if(auth)
            logginRequest(userId, lastProfile, solutionId);
    }, [auth, userId, lastProfile, solutionId]);


    return(
        <>
            {children}
        </>
    );
}

export default AccessLogger;