import React, {useContext } from 'react';
import { Routes , Route} from "react-router-dom";
import { AppRoutes } from "../routes";
import UserContext from '../utils/context/user/userContext';

// pages
import Presentation from "./public/Presentation/Presentation";
import DashboardOverview from "./dashboard/DashboardOverview/DashboardOverview";
import Signin from "./public/Signin/Signin";
import ForgotPassword from "./public/ForgotPassword/ForgotPassword";
import ResetPassword from "./public/ResetPassword/ResetPassword";
import NotFoundPage from "./public/NotFound/NotFound";
import ServerError from "./public/ServerError/ServerError";
import { DashboardMapping } from '../config/dashboards';
import PublicLoader from './routing/PublicLoader';
import RouteWithSidebar from './routing/RouteWithSidebar';
import NotFound from './public/NotFound/NotFound';
import UserProfile from './user/UserProfile';
import AccessLogger from './routing/AccessLogger';

const HomePage = () => {

  const userContext = useContext(UserContext);

  return(
    <Routes>
      <Route element={<RouteWithSidebar/>}>
      {
        userContext.user && userContext.user.solutions.filter( s => !(s.solutionType === "LEAD_GENERATION")).map( s => {
            const Component = DashboardMapping.private[s.code];
            return <Route 
              exact path={AppRoutes.Dashboards_template.path+s.code} 
              element={<AccessLogger solutionId={s.id}><Component solutionId={s.id}/></AccessLogger>}
              key={`route_${s.name}`}
            />
        })
      }
      </Route>

      <Route element={<PublicLoader/>}>
        <Route exact path={AppRoutes.Presentation.path} element={<Presentation/>} />
        <Route exact path={AppRoutes.Signin.path} element={<Signin/>} />
        <Route exact path={AppRoutes.ForgotPassword.path} element={<ForgotPassword/>} />
        <Route exact path={AppRoutes.NotFound.path} element={<NotFoundPage/>} />
        <Route exact path={AppRoutes.ServerError.path} element={<ServerError/>} />
      </Route>
      <Route exact path={AppRoutes.ResetPassword.path} element={<ResetPassword/>} />

      {
        Object.entries(DashboardMapping.public).map( entry => {
          const id = entry[0]
          const Component = entry[1]
          return <Route 
            key={`public_${id}`} exact path={AppRoutes.Dashboards_template.path+id} 
            element={<AccessLogger publicAccess={true} solutionId={id}><Component solutionId={id}/></AccessLogger>}
           />
        })
      }

       {/* pages */}
       <Route element={<RouteWithSidebar/>}>
          <Route exact path={AppRoutes.DashboardOverview.path} element={<DashboardOverview/>}/>
          <Route exact path={AppRoutes.UserProfile.path} element={<UserProfile/>}/>
      </Route>
    
      {
        !userContext.isAuth && <Route 
          path={AppRoutes.Dashboards_template.path+':id'}
          element={<Signin/>}
        />
      }
      <Route path="/*" element={<NotFound/>}/>
    </Routes>
  )
};

export default HomePage;