
import React, { useContext, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { AppRoutes } from "../../routes";
import DefaultProfilePicture from "../../assets/img/profile-pictures/default.jpg";
import { DashboardMapping, SolutionGroups } from "../../config/dashboards";
import { langMap } from "./i18n";
import LanguageContext from "../../utils/context/i18n/languageContext";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import SidebarProfileSelector from "../SidebarProfileSelector/SidebarProfileSelector";
import { updateResourceRequest } from "../../utils/request/api";
import { Endpoints } from "../../config/api";

const Sidebar = ({user, logoutHandler, profileHandler}) => {

  const navigate = useNavigate();
  const lang = useContext(LanguageContext).lang
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  
  const displayName = user.firstName ? user.firstName : user.username;

  let imagePath = DefaultProfilePicture;
  if (user.imgPath)
    imagePath = user.imgPath;

  const isDemo =  user.roles && user.roles.some(r => r.name.includes("_DEMO"));
  
  const onCollapse = () => setShow(!show);

  const onLogout = (e) => {
    e.preventDefault();
    console.log("LOGOUT");
    updateResourceRequest(Endpoints.USER_UPDATE_ON_LOGOUT.replace("{id}", user.id), {profileId: user.lastProfile, lastLanguage: lang});
    logoutHandler();
    navigate(AppRoutes.DashboardOverview.path);
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, items, children = null } = props;
    const activeKey = items.some(i => i.code === pathname.substring(pathname.lastIndexOf("/")+1)) ? eventKey : ""

    return (
      <>  
        {
          title !== "Try our data" ?
          <Accordion as={Nav.Item} defaultActiveKey={activeKey}>
              <Accordion.Item eventKey={eventKey}>
                <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                  <span className="sidebar-content">
                    <span className="sidebar-icon text-secondary-lc"><FontAwesomeIcon icon={icon} /> </span>
                    <span className="sidebar-text text-dark-lc">{title}</span>
                  </span>
                </Accordion.Button>
                <Accordion.Body className="multi-level">
                  <Nav className="flex-column">
                    {children}
                    {
                      items ? items.map ( i => {
                            return <NavItem title={i.name} external={false} link={AppRoutes.Dashboards_template.path+i.code} key={i.name}/>
                      })
                      :
                      ""
                    }
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
          </Accordion> 
          :
          <Accordion as={Nav.Item} activeKey={false} className="LG_button">
            <Accordion.Item>
              <Accordion.Button as={Nav.Link} target="_blank" href={DashboardMapping.private[items[0].code]} className="d-flex justify-content-between align-items-center">
                <span>
                  <span className="sidebar-icon text-primary-lc"><FontAwesomeIcon icon={icon} /> </span>
                  <span className="sidebar-text text-primary-lc">{title}</span>
                </span>
              </Accordion.Button>
            </Accordion.Item>
          </Accordion>
        }
      </>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={60} height={60} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        {/* <Navbar.Brand className="me-lg-5" as={Link} to={AppRoutes.DashboardOverview.path}>
          <Image src={MySolutionIcon} className="navbar-brand-light" />
        </Navbar.Brand> */}
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-quintary-lc text-dark-lc`}>
          <div className="sidebar-inner px-3 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={imagePath} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>{user.hideProfile ? "Sign out" : displayName}</h6>
                  { 
                    !isDemo && !user.hideProfile &&
                    <Button variant="tertiary" as={Link} to={AppRoutes.UserProfile.path} className="text-light m2-e">
                      <FontAwesomeIcon icon={faUserCircle} className="me-2" /> {langMap.profile[lang]}
                    </Button>
                  }
                  <Button variant="secondary" onClick={onLogout} className="text-dark m2-e">
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-primary me-2"/> {langMap.signout[lang]}
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
              <div className="fixed-bottom">
                {
                  user.profiles.length > 1 &&  <SidebarProfileSelector
                    user={user}  
                    handleChange={profileHandler} 
                    className="mobile-profile-selector d-flex d-md-none"
                  />
                }
                <LanguageSelector
                  className="mobile-lang-selector d-flex d-md-none"
                />
              </div>
            </div>

            <Nav className="flex-column user-button">
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-none d-md-flex align-items-center">
                    <Image src={imagePath} className="user-avatar md-avatar rounded-circle" />
                    <div className="truncate-text media-body ms-2 align-items-center d-lg-block">
                      <p className="mb-0 font-small truncate-text fw-bold">{user.hideProfile ? "Sign out" : displayName}</p>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right mt-2 text-dark p-0 w-100">
                  <Dropdown.Item className="fw-bold p-0 m-0">
                    { 
                      !isDemo && !user.hideProfile &&
                      <Button variant="gray-lc-900" as={Link} to={AppRoutes.UserProfile.path} className="text-quaternary-lc w-100">
                        <FontAwesomeIcon icon={faUserCircle} className="me-2 text-primary-lc" /> {langMap.profile[lang]}
                      </Button>
                    }
                  </Dropdown.Item>
                  {/* <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faUserShield} className="me-2" /> {langMap.support[lang]}
                  </Dropdown.Item> */}
                  <Dropdown.Divider />
                  <Dropdown.Item className="fw-bold p-0 m-0">
                    <Button variant="secondary-lc" onClick={onLogout} className="text-body-bg w-100">
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-body-bg me-2"/> {langMap.signout[lang]}
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>

            <Nav className="flex-column pt-4 pb-5 pt-md-2">
              <Navbar.Brand className="mx-auto pt-2 pb-2">
                <FontAwesomeIcon icon={faChartLine} className="me-2 text-secondary-lc" />
                My solutions
              </Navbar.Brand>
              {
                user.solutions && SolutionGroups.map ((group, i) => {
                  const solutions = user.solutions.filter(s => s.groupName === group.name);
                  solutions.sort((s1, s2) => s1.groupRelativeOrder - s2.groupRelativeOrder);
                  return solutions.length ? <CollapsableNavItem title={group.name} icon={group.icon} items={solutions} key={group.name} eventKey={group.name}/> : ""
                })
              }
            </Nav>            

            <Dropdown.Divider />
            {
              isDemo &&
              <Button className="mt-5 contact-us" onClick={() => window.location = `mailto:${user.email}`}>
                <p>Get in touch to discuss getting our dashboards</p>
                <p>{`Contact us at ${user.email}`}</p>
              </Button>
            }
          </div>
        </SimpleBar>
      </CSSTransition>
      <div className="fixed-bottom">
        {
          user.profiles.length > 1 &&  <SidebarProfileSelector
            user={user}  
            handleChange={profileHandler} 
            className="d-none d-md-block"
          />
        }
        <LanguageSelector
          className="d-none d-md-block"
        />
      </div>
      
    </>
  );
};

export default Sidebar;