import * as ReactDOMClient from 'react-dom/client';
import App from "./app";

// private library styles
import "../node_modules/@bge/global_solution_workbench_commons/dist/es/bundle.css";
// vendor styles
import "../node_modules/bootstrap-print-css/css/bootstrap-print.min.css"
import "@fortawesome/fontawesome-free/css/all.css";
// core styles
import "./scss/volt.scss";

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<App />);
