import { Datasources, Endpoints } from "../../../../config/api";
import { currentYear, filterYear } from "../../config/dates";
import { industryMap, socToSubjectMap, subjectsMap } from "../../config/mappings"
import { jobPostingCurrentQuarterYear, jobPostingQuarterFilterYear, quarterMonthFilter } from "../../../../utils/dates/datesUtils";

export const FE_1 = {
    recruitmentDemandTooltip: "The number of unique online job postings from Lightcast Job Posting Analytics.",
    last12MonthsFilter: { "filter": { "when": { "start": `${filterYear}-01`, "end": `${filterYear}-12`, "type": "active" }}, "metrics": [ "unique_postings" ] },
    timeseries: {
        filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}`, "type": "active" }}, "metrics": [ "unique_postings" ] },
        tooltip : "The monthly active volume of unique online job postings from Lightcast Job Posting Analytics.",
    },
    recruitementGrowth : {
        facets: ["year_on_year"],
        tooltip: "The percentage change in unique online job postings comparing one year to the previous year from Lightcast Job Posting Analytics."
    },
    totalJobs: {
        facets: ["emsi.uk.industry", `latest`],
        filter: { "metrics" : [ { "name": `Jobs.${filterYear}`}]},
        tooltip: `The number of jobs across all industries in the latest full year from Lightcast ${currentYear}.1`
    },
    unemployment: {
        facets: ["emsi.uk.econactivity.quarterly", `latest`],
        queries: {n: "Unemployed", d: "Active"},
        filter: { "metrics": [ { "name": `Population.${filterYear}.Q1`}], "constraints": [ {"dimensionName": "EconActivity", "map": { "Unemployed": ["7"], "Active": ["4"]}}]},
        tooltip: `The percent of the adult workforce unemployed but actively seeking work from Lightcast ${currentYear}.1.`
    },
    top10:{
        company:{
            facets: ["company_name"],
            filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}`, "type": "active" }, "company_is_staffing": false,  "company_name": {"exclude": ["Unclassified"],"exclude_op": "or"}}, "rank": { "by": "unique_postings", "limit": 10 }},
            tooltip: "The volume of unique online job postings for the largest 10 non-staffing companies advertising from Lightcast Job Posting Analytics."
        },
        occupation: {
            facets: ["soc4_name"],
            filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}`, "type": "active"}}, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the largest 10 SOC unit group occupations from Lightcast Job Posting Analytics."
        }
    },
    industryComposition: {
        facets: ["emsi.uk.industry", `latest`],
        filter: {
            "metrics": [
                {"name": `Jobs.${filterYear}`},
                {"name": `Jobs.${filterYear}`, "as": "LQ", "operation": {
                      "name": "LocationQuotient",
                      "geoparent": "K02000001",
                      "along": "Industry"
                    }
                }
            ],
            "constraints": [
                { "dimensionName": "Industry", "map": industryMap}
            ],
            "sortBy": [{
              "name": `Jobs.${filterYear}`, "direction": "ascending"
            }]
        },
        tooltip: `The number of jobs in each SIC section in the latest full year, with a UK equivalent (the share at UK level resized to match the local labour market) as a comparison, from Lightcast ${currentYear}.1.`
    }
};


export const FE_2 = {
    achievements: {
        facets: ["emsi.uk.furthereducation",`latest`],
        aimFilter: { "metrics": [{ "name": `Achievements.${filterYear}`}]},
        aimsTooltip : "The volume of aims achieved in the further education system from Department for Education data.",
        collegeAimsTooltip: "The volume of aims achieved by the college from Department for Education data.",
        learnerFilter: {"metrics": [ { "name": `AchievementsFullTimeLearnerEquivalent.${filterYear}`}]},
        learnerTooltip: "The modelled estimates of full-time equivalent learners achieving qualifications in the further education system, estimated by Lightcast from Department for Education data.",
        collegeLearnerTooltip: "The modelled estimates of full-time equivalent learners achieving qualifications in the college, estimated by Lightcast from Department for Education data.",
    },
    gapAnalysis: {
        endpoints: [Endpoints.TARGETS, Endpoints.TARGETS],
        datasources: [Datasources.EMSI_API_UK, Datasources.EMSI_API_UK],
        titles: ["", `Supply: learner achievements in region in ${filterYear}`, `Demand: projected job opening ${filterYear}-${filterYear+9}`, `Gap Analysis ${filterYear}`, `Specialisation Analysis ${filterYear}`],
        subtitles: ["","FTLE achievement (and England equivalent)", "Projected annual job openings (and UK equivalent)", "Gap (positive), Oversupply (negative)", "Demand LQ (Red) and Supply SQ (Green)"],
        facets: [["emsi.uk.furthereducation", `latest`],  ["emsi.uk.occupation", `latest`]],
        achievementsSupplyFilter: { 
            "metrics": [
              {
                "name": `AchievementsFullTimeLearnerEquivalent.${filterYear}`,
              }
            ],
            "constraints": [
                {
                  "dimensionName": "Program",
                  "map": subjectsMap
                }
            ]
            // "sortBy": [{
            //     "name": `Achievements.${filterYear}`,
            //     "direction": "descending"
            // }]
        },
        projectedOpeningsFilter  : { 
            "metrics": [
                { "name": `Openings.${filterYear}`},
                { "name": `Openings.${filterYear + 1}`},
                { "name": `Openings.${filterYear + 2}`},
                { "name": `Openings.${filterYear + 3}`},
                { "name": `Openings.${filterYear + 4}`},
                { "name": `Openings.${filterYear + 5}`},
                { "name": `Openings.${filterYear + 6}`},
                { "name": `Openings.${filterYear + 7}`},
                { "name": `Openings.${filterYear + 8}`},
                { "name": `Openings.${filterYear + 9}`}
                // {
                //     "name": "Openings.2030", "as": "LQ", "operation": {
                //         "name": "LocationQuotient",
                //         "geoparent": "K02000001",
                //         "along": "Occupation"
                //     }
                // }
            ],
            "constraints": [
                {
                "dimensionName": "Occupation",
                "map": socToSubjectMap
                }
            ]
            // "sortBy": [{
            //     "name": "Openings.2030",
            //     "direction": "descending"
            // }]
        },
        tooltips: [
            "The modelled estimates of full-time equivalent learners achieving qualifications by Sector Subject Area, with England-wide equivalents (the share at England level resized to match the local system) as a comparison, estimated by Lightcast from Department for Education data",
            `The estimated annual number of job openings in future years, by occupations grouped by Sector Subject Area, with England-wide equivalents (the share of England level resized to match the local system) as a comparison, from Lightcast ${currentYear}.1.`,
            "The difference between the estimated supply and demand numbers, identifying the gap between labour supply emerging from the further education system and job openings demand, grouped by Sector Subject Area"
        ],
        collegeTooltips: [
            "The modelled estimates of full-time equivalent learners achieving qualifications for the college by Sector Subject Area, with England-wide equivalents (the share at England level resized to match the local system) as a comparison, estimated by Lightcast from Department for Education data.",
            `The estimated annual number of job openings in future years, by occupations grouped by Sector Subject Area, with England-wide equivalents (the share of England level resized to match the local system) as a comparison, from Lightcast ${currentYear}.1.`,
            "The difference between the estimated supply and demand numbers, identifying the gap between labour supply emerging from the college and job openings demand, grouped by Sector Subject Area."
        ]
    }
}

export const FE_4 = {
    achievements: {
        learnerFilter: JSON.parse(JSON.stringify(FE_2.achievements.learnerFilter))
    },
    last12MonthsFilter: JSON.parse(JSON.stringify(FE_1.last12MonthsFilter)),
    timeseries: {
        filter : JSON.parse(JSON.stringify(FE_1.timeseries.filter))
    },
    top10: {
        titles:{
            facets: ["title_name"],
            filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}` }, "title_name": {"exclude": ["Unclassified"],"exclude_op": "or"}}, "rank": { "by": "unique_postings", "limit": 10 }},
            tooltip: "The volume of unique online job postings for the most relevant 10 job titles, for the selected Sector Subject Area, from Lightcast Job Posting Analytics."
        },
        hardSkills:{
            facets: ["specialized_skills_name"],
            filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}`}}, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the most relevant 10 specialized skills, for the selected Sector Subject Area, from Lightcast Job Posting Analytics."
        },
        softSkills: {
            facets: ["common_skills_name"],
            filter: { "filter": { "when": { "start": `${jobPostingQuarterFilterYear}-${quarterMonthFilter}`, "end": `${jobPostingCurrentQuarterYear}-${quarterMonthFilter}`}}, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the most relevant 10 common skills, for the selected Sector Subject Area, from Lightcast Job Posting Analytics."
        },
        company: {
            facets: ["company_name"],
            filter: JSON.parse(JSON.stringify(FE_1.top10.company.filter)),
            tooltip: "The volume of unique online job postings for the largest 10 non-staffing companies advertising, in the selected Sector Subject Area, from Lightcast Job Posting Analytics."
        },
        industries: {
            facets: ["emsi.uk.staffing", `latest`],
            filter: {
                "metrics": [
                  { "name": `Jobs.${filterYear}`}
                ],
                "constraints": [
                    {"dimensionName": "Industry", "map": industryMap}
                ],
                "sortBy": [
                    {
                        "name": `Jobs.${filterYear}`,
                        "direction": "ascending"
                    }
                ],
                "limit": 10
            },
            tooltip: "The volume of unique online job postings for the largest 10 industry sectors, relevant for the selected Sector Subject Area, from Lightcast Job Posting Analytics."
        }
    },
    openingsFilter: {
        "metrics": [
            { "name": `Openings.${filterYear}`}
        ]
    },
    projectedOpenings: {
        facets: ["emsi.uk.occupation", `latest`],
        filter: { 
            "metrics": [
                { "name": `Openings.${filterYear}`},
                { "name": `Openings.${filterYear + 1}`},
                { "name": `Openings.${filterYear + 2}`},
                { "name": `Openings.${filterYear + 3}`},
                { "name": `Openings.${filterYear + 4}`},
                { "name": `Openings.${filterYear + 5}`},
                { "name": `Openings.${filterYear + 6}`},
                { "name": `Openings.${filterYear + 7}`},
                { "name": `Openings.${filterYear + 8}`},
                { "name": `Openings.${filterYear + 9}`}
            ]
        },
        LQTooltip: `The ratio of the share of jobs in occupations relevant to the selected Sector Subject Area in the region and the share of the same jobs in the UK economy, from Lightcast ${currentYear}.1.`,
        annualOpeningsTooltip: `The estimated annual number of job openings in future years, for occupations relevant to the selected Sector Subject Area, with England-wide equivalents (the share of England level resized to match the local system) as a comparison, from Lightcast ${currentYear}.1.`
    },
    learnerTooltip : "The modelled estimates of full-time equivalent learners achieving qualifications in the selected Sector Subject Area for the college, estimated by Lightcast from Department for Education data.",
    marketShareTooltip: "The share of estimated full-time equivalent learners achieved qualifications in the selected Sector Subject Areas for the college, as a share of the further education system, estimated by Lightcast from Department for Education data.",
    recruitmentDemandTooltip: "The number of unique online job postings in occupations relevant to the selected Sector Subject Area from Lightcast Job Posting Analytics."
}