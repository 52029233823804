import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { Datasources, Endpoints } from "../../../config/api";
import UserContext from "../../../utils/context/user/userContext";
import { jobPostingCurrentYear } from "../../../utils/dates/datesUtils";
import { buildJobPostingsFilter } from "../../../utils/filters/filters";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import { barsOptionsReverse, seriesOptions } from "../config/chartOptions";
import { industryMap, socToIndustryMap } from "../config/mappings";
import { emptyFilter } from "../config/widgets";
import { EI_1, EI_3, timeseriesLegend } from "./config/widgtes";
import { BarChartWidget, Collapsible, GeneralSelector, PageBreak, PdfPrinter, SeriesValueWidget, SlidingPanel, SlidingPanelContent, SlidingPanelWidget } from "@bge/global_solution_workbench_commons";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png"

const industryObjects = Object.entries(industryMap).map( entry => {
    return {
        name: entry[0],
        code: entry[1],
        socs: socToIndustryMap[entry[0]]
    }
})

const IndustryAnalysisDashboard = ({solutionId}) => {

    const componentRef = useRef();
    const userContext = useContext(UserContext);
    const locale = useContext(LanguageContext).locale;
    const [currentIndustryInfo, setCurrentIndustryInfo] = useState(industryObjects[0]);

    const handlePrint = useReactToPrint(
        {
          documentTitle: "IndustryAnalysisDashboard.pdf",
          content: () => componentRef.current
        }
    )

    const timeseriesFilter = useMemo(() => buildJobPostingsFilter(EI_3.timeseries.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo]);
    const topOccupationsFilter = useMemo(() => buildJobPostingsFilter(EI_3.top10.occupations.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo])
    const top10HardSkillsFilter = useMemo(() => buildJobPostingsFilter(EI_3.top10.hardSkills.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo]);
    const top10SoftSkillsFilter = useMemo(() => buildJobPostingsFilter(EI_3.top10.softSkills.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo]);
    const top10JobtTitlesFilter = useMemo(() => buildJobPostingsFilter(EI_3.top10.titles.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo]);
    const top10Companiesfilter = useMemo(() => buildJobPostingsFilter(EI_3.top10.company.filter, "soc4", currentIndustryInfo.socs), [currentIndustryInfo]);

    return (
        <div ref={componentRef}>
            <DashboardHeader
                title = "Industry Analysis" 
                printer = {<PdfPrinter printHandler={handlePrint} />}
                contextWidget = {
                    <SolutionContextWidget
                        endpoint={Endpoints.RLS_INFO}
                        datasource={Datasources.EMSI_API_UK}
                        profileId={userContext.user.lastProfile}
                        solutionId={solutionId}
                        filter={emptyFilter}
                    /> 
                }
            />
            <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xl={12} className="d-block mb-4 mb-md-0">
                    <GeneralSelector
                        current={currentIndustryInfo.name}
                        passedItems={industryObjects}
                        clickHandler={setCurrentIndustryInfo}
                        requestHandler={null}
                        title="Choose Industry"
                        classSelected="industry-selector"
                        iconClass="fa-solid fa-caret-down"
                    />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <Collapsible title={`Recruitment demand - ${currentIndustryInfo.name}`}>
                        <SlidingPanel slidingPanelName="timeseriesSlide">
                            <SlidingPanelContent>
                            <div>
                                <h3>Recruitment demand</h3>
                                <p>{EI_1.timeseries.tooltip}</p>
                            </div>
                            </SlidingPanelContent>
                            <SlidingPanelWidget>
                                <SeriesValueWidget
                                    endpoint={Endpoints.TIMELINES}
                                    datasource={Datasources.EMSI_API_UK}
                                    requestHandler={dataRequest}
                                    title={`Recruitment demand - ${currentIndustryInfo.name}`}
                                    subtitle={"Unique active postings per month in the past 12 months"}
                                    filter={timeseriesFilter}
                                    startYear={jobPostingCurrentYear}
                                    additionalYears={EI_1.timeseries.additionalYears}
                                    legendLabels={timeseriesLegend}
                                    profileId={userContext.user.lastProfile}
                                    solutionId={solutionId}
                                    showTitle={false}
                                    options={seriesOptions}
                                    printWidget={true}
                                />
                            </SlidingPanelWidget>
                        </SlidingPanel>
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <Collapsible title="Top 10 job titles">
                        <SlidingPanel slidingPanelName="titlesSlide">
                            <SlidingPanelContent>
                                <div>
                                <h3>Top 10 Job titles</h3>
                                <p>{EI_3.top10.titles.tooltip}</p>
                                </div>
                            </SlidingPanelContent>
                            <SlidingPanelWidget>
                                <BarChartWidget
                                    endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_3.top10.titles.facets)}
                                    datasource={Datasources.EMSI_API_UK}
                                    requestHandler={dataRequest}
                                    title="Top 10 job titles"
                                    subtitle={`Number of job postings in the past 12 months`}
                                    filter={top10JobtTitlesFilter}
                                    profileId={userContext.user.lastProfile}
                                    solutionId={solutionId}
                                    chartClassName="ct-octave"
                                    showTitle={false}
                                    options={barsOptionsReverse}
                                    locale={locale}
                                    printWidget={true}
                                />
                            </SlidingPanelWidget>
                        </SlidingPanel>
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <Collapsible title="Top occupations">
                        <SlidingPanel slidingPanelName="occupationsSlide">
                            <SlidingPanelContent>
                                <div>
                                    <h3>Top occupations</h3>
                                    <p>{EI_3.top10.occupations.tooltip}</p>
                                </div>
                            </SlidingPanelContent>
                            <SlidingPanelWidget>
                                <BarChartWidget
                                    endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_3.top10.occupations.facets)}
                                    datasource={Datasources.EMSI_API_UK}
                                    requestHandler={dataRequest}
                                    title="Top occupations"
                                    subtitle={`Unique job postings in the past 12 months`}
                                    filter={topOccupationsFilter}
                                    profileId={userContext.user.lastProfile}
                                    solutionId={solutionId}
                                    chartClassName="ct-octave"
                                    showTitle={false}
                                    options={barsOptionsReverse}
                                    locale={locale}
                                    printWidget={true}
                                />
                            </SlidingPanelWidget>
                        </SlidingPanel>
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <Collapsible title="Top 10 specialized skills">
                        <SlidingPanel slidingPanelName="hardSkillsSlide">
                            <SlidingPanelContent>
                                <div>
                                <h3>Top 10 specialized skills</h3>
                                <p>{EI_3.top10.hardSkills.tooltip}</p>
                                </div>
                            </SlidingPanelContent>
                            <SlidingPanelWidget>
                                <BarChartWidget
                                    endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_3.top10.hardSkills.facets)}
                                    datasource={Datasources.EMSI_API_UK}
                                    requestHandler={dataRequest}
                                    title="Top 10 job specialized skills"
                                    subtitle={`Unique job postings in the past 12 months`}
                                    filter={top10HardSkillsFilter}
                                    profileId={userContext.user.lastProfile}
                                    solutionId={solutionId}
                                    chartClassName="ct-octave"
                                    showTitle={false}
                                    options={barsOptionsReverse}
                                    locale={locale}
                                    printWidget={true}
                                />
                            </SlidingPanelWidget>
                        </SlidingPanel>
                    </Collapsible>
                </Col>
            </Row>
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                    <Collapsible title="Top 10 common skills">
                        <SlidingPanel slidingPanelName="softSkillsSlide">
                            <SlidingPanelContent>
                                <div>
                                <h3>Top 10 common skills</h3>
                                <p>{EI_3.top10.softSkills.tooltip}</p>
                                </div>
                            </SlidingPanelContent>
                            <SlidingPanelWidget>
                                <BarChartWidget
                                    endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_3.top10.softSkills.facets)}
                                    datasource={Datasources.EMSI_API_UK}
                                    requestHandler={dataRequest}
                                    title="Top 10 job common skills"
                                    subtitle={`Unique job postings in the past 12 months`}
                                    filter={top10SoftSkillsFilter}
                                    profileId={userContext.user.lastProfile}
                                    solutionId={solutionId}
                                    chartClassName="ct-octave"
                                    showTitle={false}
                                    options={barsOptionsReverse}
                                    locale={locale}
                                    printWidget={true}
                                />
                            </SlidingPanelWidget>
                        </SlidingPanel>
                    </Collapsible>
                </Col>
            </Row> 
            <PageBreak logo={CompanyLogo} alt="Lightcast"/>
            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4">
                <Collapsible title="Top 10 companies">
                    <SlidingPanel slidingPanelName="companiesSlide">
                    <SlidingPanelContent>
                        <div>
                        <h3>Top 10 companies</h3>
                        <p>{EI_3.top10.company.tooltip}</p>
                        </div>
                    </SlidingPanelContent>
                    <SlidingPanelWidget>
                        <BarChartWidget
                            endpoint={buildFinalUrl(Endpoints.RANKINGS, EI_1.top10.company.facets)}
                            datasource={Datasources.EMSI_API_UK}
                            requestHandler={dataRequest}
                            title="Top 10 job companies"
                            subtitle={`Unique job postings in the past 12 months`}
                            filter={top10Companiesfilter}
                            profileId={userContext.user.lastProfile}
                            solutionId={solutionId}
                            chartClassName="ct-octave"
                            showTitle={false}
                            options={barsOptionsReverse}
                            locale={locale}
                            printWidget={true}
                        />
                    </SlidingPanelWidget>
                    </SlidingPanel>
                </Collapsible>
                </Col>
            </Row>
        </div>
    )


}

export default IndustryAnalysisDashboard;