import {CHANGE_LANG, CHANGE_PROFILE, LOGIN, LOGOUT} from "./actionTypes"


export const login = (data) => {
  return {
    type: LOGIN,
    user: data.user,
    error: data.error
  };
};
  
export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const changeProfile = (data) => {
  return  {
    type: CHANGE_PROFILE,
    user: data
  }
}


export const changeLang = (data) => {
  return {
    type: CHANGE_LANG,
    lang: data.lang,
    locale: data.locale,
    flag: data.flag
  }
}