
import React, { useEffect, useState } from "react";
import { dataRequest } from "../../../utils/request/api";
import { defaultSolutionContextWidgetData } from "./DefaultSolutionContextWidgetData";

const SolutionContextWidget = (
  {
    endpoint,
    datasource,
    filter,
    profileId,
    solutionId,
    className = ""
  }) => {

  const [data, setData] = useState(defaultSolutionContextWidgetData);
  const [error, setError] = useState("");

  const keys = Object.keys(data["constraints"]);
  
  useEffect(() => {
    dataRequest(endpoint, datasource, filter, profileId, solutionId, setData, setError);
  }, [endpoint, datasource, filter, profileId, solutionId]);

  return (
    <div className={"solution-context align-middle" + className}>
      <ul>
       { error && <li>"Service unavailable"</li> }
       { !error && data ? 
            keys.map( k => {
              return (
              <li key={k}>
                <span className="solution-context-key">
                    {k}:
                </span>
                <span className="solution-context-value">
                  {data["constraints"][k]}
                </span>
              </li>
              )
            })
            : <li>Error in Context Description</li>
          }
      </ul>
    </div>
  );
};

export default SolutionContextWidget;