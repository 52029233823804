export const langMap = {
    backButton: {
        IT: "Torna alla homepage",
        EN: "Back to homepage"
    },
    topText: {
        IT: "Reset Password",
        EN: "Reset Password"
    },
    usernameLabel: {
        IT: "Il tuo nome utente o email",
        EN: "Your username or email"
    },
    passwordLabel: {
        IT: "Nuova password",
        EN: "New password"
    },
    confirmPasswordLabel: {
        IT: "Conferma nuova password",
        EN: "Confirm new password"
    },
    confirmButton: {
        IT: "Conferma Reset",
        EN: "Confirm Reset"
    }
}