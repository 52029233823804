import React from "react";
import { Popup } from "@bge/global_solution_workbench_commons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const PasswordPopup = ({placement = "top"}) => {
    return(
        <Popup
            placement={placement}
            title="Password must be and contain:"
            textLines={[
                "-At least 8 character long",
                "-At least 1 uppercase  and 1 lowercase character",
                "-At least 1 number",
                "-At least 1 special character ( $, % , & ...)",
                "-No whitespaces"
            ]}
            buttonClass="password-info"
            icon={faCircleInfo}
            iconClass="password-info"
        />
    )
}

export default PasswordPopup;