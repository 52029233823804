export const errorMessages = {
    invalidSolutionForUser: {
        EN: "Requested invalid solution for this user"
    },
    missingDatasetVersion:  {
        EN: "You should provide the specific version for the desired dataset"
    },
    dataformatNotSupported: {
        EN: "DataFormat not supported"
    },
    datasetNotFound : {
        EN: "Dataset not found"
    },
    serviceUnavailable: {
        EN: "Sorry, this service is currently not working. Try again later."
    },
    noConcurrentAccess: {
        EN: "Concurrent access is not permitted for the same user"
    },
    missingRefreshToken: {
        EN: "Refresh token is missing"
    },
    invalidCredentials :{
        EN: "Invalid username and/or password"
    },
    missingCredentials: {
        EN: "Could not read the username and password from the HTTP request's body"
    },
    passwordError: {
        EN: "Password must be at least 8 characters long and contain at least 1 uppercase character, 1 lowercase character, 1 digit, 1 special character ( such as $, #, %) and NO whitespaces"
    },
    invalidDataRequest: {
        EN: "Invalid data request parameters"
    },
    invalidJSONBody: {
        EN: "Invalid JSON body"
    },
    serverError: {
        EN: "Server error"
    },
    JSONKeyNotFound: {
        EN: "JSON key not found"
    },
    occupationCountryCodeNotFound: {
        EN: "Occupation or country code not found"
    },
    passcodeNotFound: {
        EN: "No passcode has been found for the provided ID"
    },
    invalidPasscode: {
        EN: "Invalid passcode provided"
    },
    nameAlreadyExists : {
        EN: "This name is already in use"
    },
    userNotFound: {
        EN: "No user has been found for the provided username/email"
    },
    expiredLicense: {
        EN: "This user's license has expired"
    },
    emailAlreadyExists: {
        EN: "This email is already in use"
    },
    passwordCheck: {
        EN: "The two provided passwords don't match"
    },
    invalidRegistrationCode: {
        EN: "Invalid confirmation code provided"
    },
    wrongPassword: {
        EN: "Incorrect or missing current password"
    },
    expiredToken : {
        EN: "Expired token"
    },
    firstNameError: {
        EN: "Missing or invalid first name"
    },
    lastNameError: {
        EN: "Missing or invalid last name"
    },
    jobTitleError: {
        EN: "Missing job title"
    },
    organizationNameError: {
        EN: "Missing organization name"
    },
    homeCountryError: {
        EN: "Missing home country"
    },
    emailError: {
        EN: "Missing or invalid email"
    },
    checkError: {
        EN: "The two provided passwords don't match"
    },
    concurrentAccessError: {
        EN: "Missing concurrent access flag"
    },
    originError: {
        EN: "Missing origin website"
    },
    usernameError: {
        EN: "Missing or invalid username"
    }
}