export const langMap = {
    backButton: {
        IT: "Torna alla homepage",
        EN: "Back to homepage"
    },
    topText: {
        IT: "Accedi alla piattaforma",
        EN: "Sign In to our platform"
    },
    usernameLabel: {
        IT: "Il tuo nome utente o email",
        EN: "Your username or email"
    },
    passwordLabel: {
        IT: "La tua password",
        EN: "Your password"
    },
    rememberButton: {
        IT: "Ricordami",
        EN: "Remember me"
    },
    lostPswdButton: {
        IT: "Password dimenticata ?",
        EN: "Lost password ?"
    },
    signInButton: {
        IT: "Accedi",
        EN: "Sign In"
    }
}