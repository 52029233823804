import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { setupTableauEmbedding } from "../../../utils/tableau/TableauEmbed";

const Aviva = () => {
    useEffect(() => {
        setupTableauEmbedding('viz1699438900449');
    });

    return (
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4 d-none d-sm-block">
                <div className='tableauPlaceholder' id='viz1699438900449'>
                    <noscript>
                        <a href='#'>
                            <img alt='Home Page ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;Av&#47;Aviva_16993507390780&#47;HomePage&#47;1_rss.png' />
                        </a>
                    </noscript>
                    <object class='tableauViz'>
                        <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                        <param name='embed_code_version' value='3' />
                        <param name='site_root' value='' />
                        <param name='name' value='Aviva_16993507390780&#47;HomePage' />
                        <param name='tabs' value='no' />
                        <param name='toolbar' value='yes' />
                        <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;Av&#47;Aviva_16993507390780&#47;HomePage&#47;1.png' />
                        <param name='animate_transition' value='yes' />
                        <param name='display_static_image' value='yes' />
                        <param name='display_spinner' value='yes' />
                        <param name='display_overlay' value='yes' />
                        <param name='display_count' value='yes' />
                        <param name='language' value='en-UK' />
                        <param name='filter' value='publish=yes' />
                    </object>
                </div>
            </Col>
        </Row>
    )
}

export default Aviva;