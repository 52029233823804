import React, { useReducer} from "react";
import UserContext from "./userContext";
import * as ACTIONS from "../../actions/actions";
import * as AuthReducer from "../../reducers/authReducer";
import { clearTokens} from "../../request/api";

const UserContextState = ({children, start}) => {

  const [stateAuthReducer, dispatchAuthReducer] =  useReducer(
    AuthReducer.AuthReducer,
    start
  );

  const handleLogin = (data) => {
    dispatchAuthReducer(ACTIONS.login(data));
  };

  const handleLogout = () => {
    dispatchAuthReducer(ACTIONS.logout());
    clearTokens();
  };

  const handleProfileChange = (data) => {
    dispatchAuthReducer(ACTIONS.changeProfile(data));
  }

  return (
    <UserContext.Provider
      value={{
        isAuth: stateAuthReducer.isAuth,
        user: stateAuthReducer.user,
        error: stateAuthReducer.error,
        handleUserLogin: (data) => handleLogin(data),
        handleUserLogout: () => handleLogout(),
        handleProfileChange: (data) => handleProfileChange(data)
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextState;