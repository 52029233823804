import React, {useState, useEffect, useContext} from "react";
import {Outlet} from "react-router-dom";
import UserContext from "../../utils/context/user/userContext";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer";
import Preloader from "../../components/Preloader";
import Signin from "../public/Signin/Signin";

const RouteWithSidebar = () => {

  const userContext = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    let tooltip = document.getElementsByClassName("chartist-tooltip");
    if(tooltip.length){
      document.querySelector(".chartist-tooltip").remove();
    }
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if(userContext.isAuth){
    return (
        <>
          <Preloader show={!loaded} />
          <Sidebar user={userContext.user} logoutHandler={userContext.handleUserLogout} profileHandler={userContext.handleProfileChange}/>
          <main className="content">
            <Outlet/>
            <Footer />
          </main>
        </>
    );
  }
  else { 
    return (
      <Signin/>
    );
  }
};

export default RouteWithSidebar;
