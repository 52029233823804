import React, {useEffect, useState} from 'react';
import UserContextState from "./utils/context/user/userContextStateConfig";
import { BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { userInfoFromJwtRequest } from "./utils/request/api";
import LanguageContextState from './utils/context/i18n/languageContextStateConfig';

const getUserInfoFromJwt = async() => {
    console.log("Checking tokens...")
  
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const hideProfile = localStorage.getItem("hideProfile");
  
    if(token && refreshToken){
      const response = await userInfoFromJwtRequest(token, refreshToken);
      if (response.status === 200){
        let user = response.user
        if (!user.lastProfile && user.profiles.length){
            user.lastProfile = user.profiles[0].id
        }
        if (hideProfile) {
            user.hideProfile = true;
        }
        return {user: user, isAuth: true, error: ""};
      }
      // refresh token expired
      if (response.status === 401){
        return {user: null, isAuth: false, error: response.error}
      }
      // service down
      return {user:null, isAuth: false, error: response.error}
    };
    // no tokens in local storage
    return {user: null, isAuth: false, error: ""};
};


const App = () => {

    const [startingState, setStartingState] = useState({user: null, isAuth: false, error: ""});
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        (async () => {
            const start = await getUserInfoFromJwt();
            setStartingState(start);
            setLoading(false);
        })();
    }, []);

    if(!loading){
        return (
            <LanguageContextState >
                <UserContextState start={startingState}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <HomePage />
                   </BrowserRouter>
                </UserContextState>
            </LanguageContextState>
        );
    };
    return <p>Loading</p>
};


export default App;