import React, { useContext, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import UserContext from "../../../utils/context/user/userContext";
import { faGraduationCap, faStar } from "@fortawesome/free-solid-svg-icons";
import { FE_2 } from "./config/widgets";
import { Datasources, Endpoints } from "../../../config/api";
import { subjectsMap } from "../config/mappings";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { useReactToPrint } from "react-to-print";
import { targetOptions, barsOptions, dotsOptions } from "../config/chartOptions";
import { filterYear } from "../config/dates";
import { emptyFilter } from "../config/widgets";
import { CounterWidget, GapAnalysisWidget, PdfPrinter, SlidingPanel, SlidingPanelContent, SlidingPanelWidget } from "@bge/global_solution_workbench_commons";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png"

const showTitles = [true, false, false, false, false];

const labels = Object.keys(subjectsMap);

const options = [targetOptions,barsOptions,dotsOptions];

const RegionalContextDashboard = ({ solutionId }) => {

  const componentRef = useRef();
  const userContext = useContext(UserContext);
  const locale = useContext(LanguageContext).locale;

  const handlePrint = useReactToPrint(
    {
      documentTitle: "Regional_Supply_and_Demand_Analysis.pdf",
      content: () => componentRef.current
    }
  )

  return (
    <div ref={componentRef}>
      <DashboardHeader 
        title = "Regional Supply and Demand Analysis"
        printer = { <PdfPrinter printHandler={handlePrint} />}
        contextWidget = {
          <SolutionContextWidget
            endpoint={Endpoints.RLS_INFO}
            datasource={Datasources.EMSI_API_UK}
            profileId={userContext.user.lastProfile}
            solutionId={solutionId}
            filter={emptyFilter}
          />
        }
      />
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="aimsAchievementsSlide">
            <SlidingPanelContent>
              <div>
                <h3>Aims achievements</h3>
                <p>{FE_2.achievements.aimsTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
                <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.TOTALS, FE_2.achievements.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Aims achievements (whole of region)"
                  subtitle={`Aims achieved in ${filterYear}`}
                  filter={FE_2.achievements.aimFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faGraduationCap}
                  iconColor="shape-secondary"
                  showTitle={showTitles}
                  locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel>
            <SlidingPanelContent slidingPanelName="learnerAchivementsSlide">
              <div>
                <h3>Learner achievements</h3>
                <p>{FE_2.achievements.learnerTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
                <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.TOTALS, FE_2.achievements.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Learner achievements (whole of region)"
                  subtitle={`FTLE achieved in ${filterYear}`}
                  filter={FE_2.achievements.learnerFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faStar}
                  iconColor="shape-secondary"
                  isPercentage={false}
                  decimalDigits={0}
                  locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
            <GapAnalysisWidget
              endpoint={[buildFinalUrl(FE_2.gapAnalysis.endpoints[0], FE_2.gapAnalysis.facets[0]), buildFinalUrl(FE_2.gapAnalysis.endpoints[1], FE_2.gapAnalysis.facets[1])]}
              datasource={FE_2.gapAnalysis.datasources}
              requestHandler={dataRequest}
              title={FE_2.gapAnalysis.titles}
              subtitle={FE_2.gapAnalysis.subtitles}
              tooltips={FE_2.gapAnalysis.tooltips}
              filter={[FE_2.gapAnalysis.achievementsSupplyFilter, FE_2.gapAnalysis.projectedOpeningsFilter]}
              profileId={userContext.user.lastProfile}
              solutionId={solutionId}
              chartClassName="fe-dashboard ct-perfect-fifth industryCompCell"
              options={options}
              locale={locale}
              labels={labels}
              logo={CompanyLogo}
              alt="Lightcast"
            />
        </Col>
      </Row>
    </div>
  );
};

export default RegionalContextDashboard;