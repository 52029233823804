import { industryMap } from "../../config/mappings";
import { currentYear, filterYear } from "../../config/dates";
import { jobPostingCurrentYear, jobPostingFilterYear, prevMonthFilter, monthMapping } from "../../../../utils/dates/datesUtils";

const labelYears = [filterYear-4, filterYear-3, filterYear-2, filterYear-1, filterYear, currentYear];
export const allowedYears = [filterYear-2, filterYear-1, filterYear, currentYear];

const timeseriesLegend = {};

for (const year of labelYears){
    timeseriesLegend[year] = `${monthMapping[prevMonthFilter]} ${year-1} - ${monthMapping[prevMonthFilter]} ${year}`;
}

export {timeseriesLegend};

export const EI_1 = {
    employment: {
        facets: ["emsi.uk.industry", `latest`],
        filter: { "metrics" : [ { "name": `Jobs.${filterYear}`}]},
        tooltip: `The number of jobs, across all industries, in the latest full year from Lightcast ${currentYear}.1`
    },
    unemployment: {
        facets: ["emsi.uk.econactivity.quarterly", `latest`],
        queries: {n: "Unemployed", d: "Active"},
        filter: { "metrics": [ { "name": `Population.${filterYear}.Q1`}], "constraints": [ {"dimensionName": "EconActivity", "map": { "Unemployed": ["7"], "Active": ["4"]}}]},
        tooltip: `The percent of the adult workforce unemployed but actively seeking work from Lightcast ${currentYear}.1.`
    },
    population: {
        facets: ["emsi.uk.econactivity.quarterly", `latest`],
        filter: { "metrics" : [ { "name": `Population.${filterYear}.Q1`}]},
        tooltip: `The number of people living in the area from Lightcast ${currentYear}.1`
    },
    recruitmentDemand: {
        tooltip: "The number of unique online job postings from Lightcast Job Posting Analytics.",
        filter: { "filter": { "when": { "start": `${filterYear}-01`, "end": `${filterYear}-12`}}, "metrics": [ "unique_postings" ] }
    },
    timeseries: {
        tooltip: "The monthly active volume of unique online job postings from Lightcast Job Posting Analytics.",
        filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`, "type": "active" }}, "metrics": [ "unique_postings" ] },
        additionalYears: [jobPostingFilterYear]
    },
    top10: {
        company:{
            facets: ["company_name"],
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`, "type": "active" }, "company_is_staffing": false,  "company_name": {"exclude": ["Unclassified"],"exclude_op": "or"}}, "rank": { "by": "unique_postings", "limit": 10 }},
            tooltip: "The volume of unique online job postings for the largest 10 non-staffing companies advertising from Lightcast Job Posting Analytics."
        },
        occupation: {
            facets: ["soc4_name"],
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`, "type": "active"}}, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the largest 10 SOC unit group occupations from Lightcast Job Posting Analytics."
        }
    },
    industryComposition: {
        facets: ["emsi.uk.industry", `latest`],
        filter: {
            "metrics": [
                {"name": `Jobs.${filterYear}`},
                {"name": `Jobs.${filterYear}`, "as": "LQ", "operation": {
                      "name": "LocationQuotient",
                      "geoparent": "K02000001",
                      "along": "Industry"
                    }
                }
            ],
            "constraints": [
                { "dimensionName": "Industry", "map": industryMap}
            ],
            "sortBy": [{
              "name": "Industry", "direction": "descending"
            }]
        },
        tooltip: `The number of jobs in each SIC section in the latest full year, with a UK equivalent (the share at UK level resized to match the local labour market) as a comparison, from Lightcast ${currentYear}.1.`
    }
}

export const EI_2 = {
    population: {
        filter: JSON.parse(JSON.stringify(EI_1.population.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.population.filter))
    },
    employment : {
        filter: JSON.parse(JSON.stringify(EI_1.employment.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.employment.filter))
    },
    unemployment : {
        filter: JSON.parse(JSON.stringify(EI_1.unemployment.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.unemployment.filter))
    },
    recruitmentDemand: {
        filter: JSON.parse(JSON.stringify(EI_1.recruitmentDemand.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.recruitmentDemand.filter))
    },
    timeseries: {
        filter: JSON.parse(JSON.stringify(EI_1.timeseries.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.timeseries.filter))
    },
    industryComposition : {
        filter: JSON.parse(JSON.stringify(EI_1.industryComposition.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.industryComposition.filter))
    },
    company: {
        filter: JSON.parse(JSON.stringify(EI_1.top10.company.filter)),
        benchmark: JSON.parse(JSON.stringify(EI_1.top10.company.filter))
    },
    userAreasKey: "EIBenchmarkAreas"
}

export const EI_3 = {
    timeseries: {
        filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`, "type": "active" }}, "metrics": [ "unique_postings" ] }
    },
    top10: {
        occupations: {
            facets:["soc4_name"],
            filter: {"filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}` } }, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for up to the most relevant 10 classified occupations, for the selected Industry, from Lightcast Job Posting Analytics."
        },
        hardSkills: {
            facets: ["specialized_skills_name"],
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}` } }, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the most relevant 10 specialized skills, for the selected Industry, from Lightcast Job Posting Analytics."
        },
        softSkills: {
            facets: ["common_skills_name"],
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}` } }, "rank" : {"by": "unique_postings","limit": 10}},
            tooltip: "The volume of unique online job postings for the most relevant 10 common skills, for the selected Industry, from Lightcast Job Posting Analytics."
        },
        company: {
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`, "type": "active" }, "company_is_staffing": false,  "company_name": {"exclude": ["Unclassified"],"exclude_op": "or"}}, "rank": { "by": "unique_postings", "limit": 10 }},
            tooltip: "The volume of unique online job postings for the largest 10 non-staffing companies advertising, in the selected Industry from Lightcast Job Posting Analytics."
        },
        titles:{
            facets: ["title_name"],
            filter: { "filter": { "when": { "start": `${jobPostingFilterYear}-${prevMonthFilter}`, "end": `${jobPostingCurrentYear}-${prevMonthFilter}`}, "title_name": {"exclude": ["Unclassified"],"exclude_op": "or"}}, "rank": { "by": "unique_postings", "limit": 10 }},
            tooltip: "The volume of unique online job postings for the most relevant 10 job titles, for the selected Industry, from Lightcast Job Posting Analytics."
        }
    }
}



