export const industryMap = {
    "Accommodation and food service activities": ["I"],
    "Administrative and support services": ["N"],
    "Agriculture, forestry and fishing": ["A"],
    "Arts, entertainment and recreation": ["R"],
    "Construction": ["F"],
    "Education": ["P"],
    // "Electricity, gas, steam and air conditioning supply": ["D"],
    "Financial and insurance activities": ["K"],
    "Human health and social work activities": ["Q"],
    "Information and communication": ["J"],
    "Manufacturing": ["C"],
    "Mining and quarrying": ["B"],
    "Other service activities": ["S"],
    "Professional, scientific and technical activities": ["M"],
    "Public administration and defence; compulsory social security": ["O"],
    "Real estate activities": ["L"],
    "Transportation and storage": ["H"],
    "Utilities and waste": ["E"],
    "Wholesale and retail trade and repair of motor vehicles and motorcycles": ["G"]
};


export const subjectsMap = {
    "Health, Public Services and Care": ["1.0"],
    "Science and Mathematics": ["2.0"],
    "Agriculture, Horticulture and Animal Care": ["3.0"],
    "Engineering and Manufacturing Technologies": ["4.0"],
    "Construction, Planning and the Built Environment": ["5.0"],
    "Information and Communication Technology": ["6.0"],
    "Retail and Commercial Enterprise": ["7.0"],
    "Leisure, Travel and Tourism": ["8.0"],
    "Arts, Media and Publishing": ["9.0"],
    "History, Philosophy and Theology": ["10.0"],
    "Social Sciences": ["11.0"],
    "Languages, Literature and Culture": ["12.0"],
    "Education and Training": ["13.0"],
    "Business, Administration and Law": ["15.0"],
};

export const socToSubjectMap = { 
    "Health, Public Services and Care": ["1112", "6132", "6231", "3541", "2259", "5224", "9223", "1251", "6131", "3212", "3319", "9131", "5245", "5249", "3232", "6111", "1162", "2440", "1163", "3229", "9225", "6133", "3211", "3222", "2222", "3221", "6232", "9224", "2461", "6240", "2211", "2212", "2221", "3314", "3571", "2255", "3224", "1254", "6134", "2256", "2113", "3111", "3311", "2462", "2411", "2419", "2412", "2251", "3223", "3313", "3214", "3219", "6311", "3560", "7114", "2321", "2322", "4111", "4112", "6114", "6116", "6137", "2253", "2112", "2113", "2483", "2254", "3555", "1161", "2231", "3581", "2464", "2469", "2225", "2226", "6135", "2232", "2233", "2234", "2235", "2236", "2237", "6117", "2223", "3213", "3520", "2224", "2229", "6136", "2252", "9231", "3582", "3312"],
    "Science and Mathematics": ["2112", "2113", "2124", "2111", "2254", "2151", "2113", "3111", "2151", "2123", "2127", "2129", "2114", "2232", "2233", "2234", "2235", "2236", "2237", "3119", "2433", "2251", "8113", "3213", "2122", "2126", "2161", "2211", "2212", "2129", "2121", "2125", "2152", "2119", "2162", "3312"],
    "Agriculture, Horticulture and Animal Care": ["2483", "2151", "9119", "2151", "8229", "5212", "5113", "6129", "5111", "3240", "2240", "5443", "5119", "8229", "6121", "1212", "9112", "1211", "5114", "9111", "2152", "5112"],
    "Engineering and Manufacturing Technologies": ["3511", "8221", "2111", "5411", "5231", "8212", "8153", "5422", "5244", "3112", "1140", "1241", "8114", "5234", "3212", "8114", "3116", "3119", "8132", "5419", "8113", "8159", "5245", "5249", "8131", "3511", "2125", "8211", "8234", "2133", "5413", "8214", "8219", "9132", "8119", "5316", "5212", "5412", "8239", "5213", "5243", "5232", "8229", "8115", "3115", "8135", "2122", "2126", "3114", "5317", "2121", "5236", "5235", "8111", "5441", "8133", "2124", "8152", "8213", "2113", "3111", "8229", "4134", "1123", "2123", "5442", "5423", "8231", "8112", "5223", "5250", "3113", "5419", "5221", "8120", "8232", "8233", "8134", "8141", "8132", "8119", "2127", "2129", "5212", "5211", "8149", "2481", "5319", "8222", "2129", "5421", "5312", "5313"],
    "Construction, Planning and the Built Environment": ["2453", "2451", "3132", "8152", "2455", "5241", "5246", "5314", "8229", "5316", "2452", "8153", "1122", "5323", "5225", "3112", "2452", "3581", "3120", "9121", "9129", "5330", "5321", "5315", "5322", "8159", "5245", "5249", "5311", "5319", "5250", "2454", "8222", "3114", "2452", "5317", "2121", "8151", "5312", "5313"],
    "Information and Communication Technology": ["2141", "3132", "5242", "2132", "3131", "3133", "2133", "2134", "5244", "2135", "2136", "2137", "2139", "3112", "5223", "2131"],
    "Retail and Commercial Enterprise": ["9259", "9251", "9252", "9253", "1223", "6222", "3555", "6219", "7132", "6231", "4151", "9269", "1242", "1243", "7219", "3551", "9261", "9263", "9266", "5432", "4134", "7111", "9262", "7115", "1221", "6250", "1253", "9223", "4133", "9211", "1140", "1241", "1251", "7131", "5436", "6138", "1150", "9265", "6232", "1224", "9264", "5434", "6221", "9224", "6240", "3214", "3219", "1233", "1255", "1256", "1257", "1258", "1259", "6211", "9226", "1222", "9229", "7112", "3542", "5435", "9221", "7129"],
    "Leisure, Travel and Tourism": ["8239", "8229", "3512", "3431", "6211", "8232", "3432", "6214", "1225", "1224", "6219", "1221", "6250", "6212", "3433", "6213", "6137"],
    "Arts, Media and Publishing": ["3421", "3422", "3429", "2494", "2134", "5413", "3414", "3417", "5411", "4135", "2493", "2141", "5432", "5224", "5422", "2135", "2136", "2137", "2139", "8119", "2491", "2492", "2131", "2472", "3411", "5442", "5423", "5443", "2132", "5419", "8112", "3416", "5449", "1137", "2471", "2142", "3415", "3412", "5419", "3413", "5421", "5441"],
    "History, Philosophy and Theology": ["2115", "2463"],
    "Social Sciences": ["2115", "2464", "2469", "2433", "2461"],
    "Languages, Literature and Culture": ["3412", "2115", "2119", "2162"],
    "Education and Training": ["2323", "3572", "3573", "3574", "6113", "2311", "2313", "3231", "6112", "2321", "2322", "8215", "2312", "2317", "2319", "2324", "2329", "2316", "2314", "2315", "3581"],
    "Business, Administration and Law": ["3572", "8143", "4121", "2432", "3556", "1121", "4151", "1134", "2434", "1242", "1243", "3541", "7111", "1221", "6250", "4211", "7214", "1140", "1241", "1251", "3553", "7125", "4136", "2421", "3531", "1131", "1137", "1233", "1255", "1256", "1257", "1258", "1259", "2440", "4143", "7220", "1136", "4215", "2455", "4216", "4131", "3533", "1232", "3534", "2131", "7131", "2433", "3115", "1211", "4159", "9233", "1131", "3571", "3573", "3574", "2494", "1254", "1133", "2431", "7132", "2422", "4134", "4152", "4217", "1122", "1123", "7115", "1231", "1252", "2411", "2419", "3554", "1150", "2412", "3532", "2482", "7121", "4213", "8144", "7213", "4214", "2435", "2439", "4212", "4122", "2423", "1223", "3555", "4129", "2493", "7113", "7219", "3551", "1135", "1139", "3557", "7212", "7211", "4142", "1132", "4124", "4132", "2481", "1212", "4141", "3520", "1172", "2161", "1222", "7112", "3552", "4123", "7129", "1171"] 
};

export const socToIndustryMap = { 
    "Accommodation and food service activities": ["1222", "1223", "9265", "9264", "1221", "6250", "5434", "9261", "9263", "9266", "5436", "5435", "6231"],
    "Administrative and support services": ["7211", "7219", "7129", "4143", "7220", "9221", "6121", "6212", "1225", "9231", "3571", "4136", "9223", "6240"],
    "Agriculture, forestry and fishing": ["5111", "9111", "1211", "9112", "5119", "5112", "8229", "9119", "5212", "1212"],
    "Arts, entertainment and recreation": ["3431", "6211", "3433", "1224", "3432", "9267", "3415", "2472", "5114", "4135", "2151", "3413", "3414", "2471", "3411", "6219", "9269", "3412", "3557", "5113", "4129", "9231", "1233", "1255", "1256", "1257", "1258", "1259", "6117"],
    "Construction": ["2121", "5241", "5246", "8159", "5314", "8151", "5321", "5312", "5313", "5315", "8152", "5316", "5323", "5322", "1122", "9121", "9129", "5225", "5330", "5319", "2455", "2453", "5311", "8221", "3114"],
    "Education": ["2312", "2311", "2321", "2322", "2313", "3231", "6112", "4213", "2314", "2315", "2316", "9232", "6113", "2317", "2319", "2324", "2329", "2119", "2162", "2323", "3222", "8215", "2115"],
    "Financial and insurance activities": ["7122", "3532", "3531", "4123", "1131", "4132", "2422", "2433", "7121", "3534", "3541", "1131", "2440", "4124", "3533"],
    "Human health and social work activities": ["6137", "6133", "2256", "2254", "2255", "2231", "2221", "2211", "2212", "2253", "6132", "2222", "9262", "4211", "2232", "2233", "2234", "2235", "2236", "2237", "1231", "6131", "2223", "6136", "6135", "2461", "2224", "2229", "1232", "6114", "6116", "2259", "1171", "2225", "2226", "1172", "3214", "3219", "3213", "3229", "6134", "2464", "2469", "3232", "6111", "3224", "3221", "4113"],
    "Information and communication": ["5242", "5243", "2491", "2492", "2135", "2136", "2137", "2139", "2134", "3416", "1137", "2131", "5244", "2141", "2133", "3132", "2132", "3131", "3133", "2494", "2124", "3417", "2434", "7213"],
    "Manufacturing": ["5224", "2125", "3113", "5235", "2129", "2113", "3111", "8114", "8115", "5212", "8114", "8142", "8160", "8119", "5211", "5419", "8115", "5221", "8120", "8141", "8131", "8119", "5222", "5213", "8112", "5441", "5411", "8146", "8111", "8149", "8135", "5234", "5422", "5442", "3116", "5423", "5317", "5421", "1121", "5223", "8143", "5449", "9139", "5419", "2122", "2126", "9132", "5433", "5432", "3115", "5250", "5412", "5413"],
    "Mining and quarrying": ["8132", "1123", "8132", "5214", "8229", "8144", "5212", "8113", "3119", "8139", "2114", "3582", "2127", "2129", "4214"],
    "Other service activities": ["6138", "6221", "2463", "1253", "6222", "6129", "9224"],
    "Professional, scientific and technical activities": ["3240", "2240", "2451", "2412", "4212", "2423", "2452", "2452", "2411", "2419", "1133", "2152", "3520", "2431", "2421", "7214", "2142", "3421", "3422", "3429", "3120", "2493", "4122", "2151"],
    "Public administration and defence; compulsory social security": ["6311", "3312", "1162", "3313", "1163", "1112", "4111", "3314", "4112", "2483", "2435", "2439", "3560", "3319", "2462", "3581", "2452"],
    "Real estate activities": ["3555", "1251", "3223", "2454", "6232"],
    "Transportation and storage": ["8232", "8211", "8231", "3511", "8233", "6214", "3511", "8212", "6213", "8234", "3512", "9211", "8153", "8213", "1140", "1241", "4134", "5236", "6312", "8239", "9259", "9251", "9252", "9253", "8222", "3542", "7212", "8214", "8219", "1242", "1243"],
    "Utilities and waste": ["8133", "2123", "3112", "5245", "5249", "2481", "8134", "9225", "1254", "9222", "9229", "9131", "2111"],
    "Wholesale and retail trade and repair of motor vehicles and motorcycles": ["3211", "9241", "8145", "5443", "7112", "2252", "1252", "7115", "7111", "7131", "9249", "7132", "7114", "1150", "9226", "7124", "5431", "5231", "5233", "7123", "5232", "3553", "7125"]
    }

export const areaLevel4Map = {
    "Aberdeen City": "S12000033",
    "Aberdeenshire": "S12000034",
    "Adur": "E07000223",
    "Allerdale": "E07000026",
    "Amber Valley": "E07000032",
    "Angus": "S12000041",
    "Antrim and Newtownabbey": "N09000001",
    "Argyll and Bute": "S12000035",
    "Armagh, Banbridge and Craigavon": "N09000002",
    "Arun": "E07000224",
    "Ashfield": "E07000170",
    "Ashford": "E07000105",
    "Aylesbury Vale": "E07000004",
    "Babergh": "E07000200",
    "Barking and Dagenham": "E09000002",
    "Barnet": "E09000003",
    "Barnsley": "E08000016",
    "Barrow-in-Furness": "E07000027",
    "Basildon": "E07000066",
    "Basingstoke and Deane": "E07000084",
    "Bassetlaw": "E07000171",
    "Bath and North East Somerset": "E06000022",
    "Bedford": "E06000055",
    "Belfast": "N09000003",
    "Bexley": "E09000004",
    "Birmingham": "E08000025",
    "Blaby": "E07000129",
    "Blackburn with Darwen": "E06000008",
    "Blackpool": "E06000009",
    "Blaenau Gwent": "W06000019",
    "Bolsover": "E07000033",
    "Bolton": "E08000001",
    "Boston": "E07000136",
    "Bournemouth": "E06000028",
    "Bracknell Forest": "E06000036",
    "Bradford": "E08000032",
    "Braintree": "E07000067",
    "Breckland": "E07000143",
    "Brent": "E09000005",
    "Brentwood": "E07000068",
    "Bridgend": "W06000013",
    "Brighton and Hove": "E06000043",
    "Bristol, City of": "E06000023",
    "Broadland": "E07000144",
    "Bromley": "E09000006",
    "Bromsgrove": "E07000234",
    "Broxbourne": "E07000095",
    "Broxtowe": "E07000172",
    "Burnley": "E07000117",
    "Bury": "E08000002",
    "Caerphilly": "W06000018",
    "Calderdale": "E08000033",
    "Cambridge": "E07000008",
    "Camden": "E09000007",
    "Cannock Chase": "E07000192",
    "Canterbury": "E07000106",
    "Cardiff": "W06000015",
    "Carlisle": "E07000028",
    "Carmarthenshire": "W06000010",
    "Castle Point": "E07000069",
    "Causeway Coast and Glens": "N09000004",
    "Central Bedfordshire": "E06000056",
    "Ceredigion": "W06000008",
    "Charnwood": "E07000130",
    "Chelmsford": "E07000070",
    "Cheltenham": "E07000078",
    "Cherwell": "E07000177",
    "Cheshire East": "E06000049",
    "Cheshire West and Chester": "E06000050",
    "Chesterfield": "E07000034",
    "Chichester": "E07000225",
    "Chiltern": "E07000005",
    "Chorley": "E07000118",
    "Christchurch": "E07000048",
    "City of Edinburgh": "S12000036",
    "City of London": "E09000001",
    "Clackmannanshire": "S12000005",
    "Colchester": "E07000071",
    "Comhairle nan Eilean Siar": "S12000013",
    "Conwy": "W06000003",
    "Copeland": "E07000029",
    "Corby": "E07000150",
    "Cornwall": "E06000052",
    "Cotswold": "E07000079",
    "County Durham": "E06000047",
    "Coventry": "E08000026",
    "Craven": "E07000163",
    "Crawley": "E07000226",
    "Croydon": "E09000008",
    "Dacorum": "E07000096",
    "Darlington": "E06000005",
    "Dartford": "E07000107",
    "Daventry": "E07000151",
    "Denbighshire": "W06000004",
    "Derby": "E06000015",
    "Derbyshire Dales": "E07000035",
    "Derry and Strabane": "N09000005",
    "Doncaster": "E08000017",
    "Dover": "E07000108",
    "Dudley": "E08000027",
    "Dumfries and Galloway": "S12000006",
    "Dundee City": "S12000042",
    "Ealing": "E09000009",
    "East Ayrshire": "S12000008",
    "East Cambridgeshire": "E07000009",
    "East Devon": "E07000040",
    "East Dorset": "E07000049",
    "East Dunbartonshire": "S12000045",
    "East Hampshire": "E07000085",
    "East Hertfordshire": "E07000242",
    "East Lindsey": "E07000137",
    "East Lothian": "S12000010",
    "East Northamptonshire": "E07000152",
    "East Renfrewshire": "S12000011",
    "East Riding of Yorkshire": "E06000011",
    "East Staffordshire": "E07000193",
    "Eastbourne": "E07000061",
    "Eastleigh": "E07000086",
    "Eden": "E07000030",
    "Elmbridge": "E07000207",
    "Enfield": "E09000010",
    "Epping Forest": "E07000072",
    "Epsom and Ewell": "E07000208",
    "Erewash": "E07000036",
    "Exeter": "E07000041",
    "Falkirk": "S12000014",
    "Fareham": "E07000087",
    "Fenland": "E07000010",
    "Fermanagh and Omagh": "N09000006",
    "Fife": "S12000015",
    "Flintshire": "W06000005",
    "Forest Heath": "E07000201",
    "Forest of Dean": "E07000080",
    "Fylde": "E07000119",
    "Gateshead": "E08000037",
    "Gedling": "E07000173",
    "Glasgow City": "S12000046",
    "Gloucester": "E07000081",
    "Gosport": "E07000088",
    "Gravesham": "E07000109",
    "Great Yarmouth": "E07000145",
    "Greenwich": "E09000011",
    "Guildford": "E07000209",
    "Gwynedd": "W06000002",
    "Hackney": "E09000012",
    "Halton": "E06000006",
    "Hambleton": "E07000164",
    "Hammersmith and Fulham": "E09000013",
    "Harborough": "E07000131",
    "Haringey": "E09000014",
    "Harlow": "E07000073",
    "Harrogate": "E07000165",
    "Harrow": "E09000015",
    "Hart": "E07000089",
    "Hartlepool": "E06000001",
    "Hastings": "E07000062",
    "Havant": "E07000090",
    "Havering": "E09000016",
    "Herefordshire, County of": "E06000019",
    "Hertsmere": "E07000098",
    "High Peak": "E07000037",
    "Highland": "S12000017",
    "Hillingdon": "E09000017",
    "Hinckley and Bosworth": "E07000132",
    "Horsham": "E07000227",
    "Hounslow": "E09000018",
    "Huntingdonshire": "E07000011",
    "Hyndburn": "E07000120",
    "Inverclyde": "S12000018",
    "Ipswich": "E07000202",
    "Isle of Anglesey": "W06000001",
    "Isle of Wight": "E06000046",
    "Isles of Scilly": "E06000053",
    "Islington": "E09000019",
    "Kensington and Chelsea": "E09000020",
    "Kettering": "E07000153",
    "King's Lynn and West Norfolk": "E07000146",
    "Kingston upon Hull, City of": "E06000010",
    "Kingston upon Thames": "E09000021",
    "Kirklees": "E08000034",
    "Knowsley": "E08000011",
    "Lambeth": "E09000022",
    "Lancaster": "E07000121",
    "Leeds": "E08000035",
    "Leicester": "E06000016",
    "Lewes": "E07000063",
    "Lewisham": "E09000023",
    "Lichfield": "E07000194",
    "Lincoln": "E07000138",
    "Lisburn and Castlereagh": "N09000007",
    "Liverpool": "E08000012",
    "Luton": "E06000032",
    "Maidstone": "E07000110",
    "Maldon": "E07000074",
    "Malvern Hills": "E07000235",
    "Manchester": "E08000003",
    "Mansfield": "E07000174",
    "Medway": "E06000035",
    "Melton": "E07000133",
    "Mendip": "E07000187",
    "Merthyr Tydfil": "W06000024",
    "Merton": "E09000024",
    "Mid Devon": "E07000042",
    "Mid Suffolk": "E07000203",
    "Mid Sussex": "E07000228",
    "Mid Ulster": "N09000009",
    "Mid and East Antrim": "N09000008",
    "Middlesbrough": "E06000002",
    "Midlothian": "S12000019",
    "Milton Keynes": "E06000042",
    "Mole Valley": "E07000210",
    "Monmouthshire": "W06000021",
    "Moray": "S12000020",
    "Neath Port Talbot": "W06000012",
    "New Forest": "E07000091",
    "Newark and Sherwood": "E07000175",
    "Newcastle upon Tyne": "E08000021",
    "Newcastle-under-Lyme": "E07000195",
    "Newham": "E09000025",
    "Newport": "W06000022",
    "Newry, Mourne and Down": "N09000010",
    "North Ayrshire": "S12000021",
    "North Devon": "E07000043",
    "North Dorset": "E07000050",
    "North Down and Ards": "N09000011",
    "North East Derbyshire": "E07000038",
    "North East Lincolnshire": "E06000012",
    "North Hertfordshire": "E07000099",
    "North Kesteven": "E07000139",
    "North Lanarkshire": "S12000044",
    "North Lincolnshire": "E06000013",
    "North Norfolk": "E07000147",
    "North Somerset": "E06000024",
    "North Tyneside": "E08000022",
    "North Warwickshire": "E07000218",
    "North West Leicestershire": "E07000134",
    "Northampton": "E07000154",
    "Northumberland": "E06000057",
    "Norwich": "E07000148",
    "Nottingham": "E06000018",
    "Nuneaton and Bedworth": "E07000219",
    "Oadby and Wigston": "E07000135",
    "Oldham": "E08000004",
    "Orkney Islands": "S12000023",
    "Oxford": "E07000178",
    "Pembrokeshire": "W06000009",
    "Pendle": "E07000122",
    "Perth and Kinross": "S12000024",
    "Peterborough": "E06000031",
    "Plymouth": "E06000026",
    "Poole": "E06000029",
    "Portsmouth": "E06000044",
    "Powys": "W06000023",
    "Preston": "E07000123",
    "Purbeck": "E07000051",
    "Reading": "E06000038",
    "Redbridge": "E09000026",
    "Redcar and Cleveland": "E06000003",
    "Redditch": "E07000236",
    "Reigate and Banstead": "E07000211",
    "Renfrewshire": "S12000038",
    "Rhondda Cynon Taf": "W06000016",
    "Ribble Valley": "E07000124",
    "Richmond upon Thames": "E09000027",
    "Richmondshire": "E07000166",
    "Rochdale": "E08000005",
    "Rochford": "E07000075",
    "Rossendale": "E07000125",
    "Rother": "E07000064",
    "Rotherham": "E08000018",
    "Rugby": "E07000220",
    "Runnymede": "E07000212",
    "Rushcliffe": "E07000176",
    "Rushmoor": "E07000092",
    "Rutland": "E06000017",
    "Ryedale": "E07000167",
    "Salford": "E08000006",
    "Sandwell": "E08000028",
    "Scarborough": "E07000168",
    "Scottish Borders": "S12000026",
    "Sedgemoor": "E07000188",
    "Sefton": "E08000014",
    "Selby": "E07000169",
    "Sevenoaks": "E07000111",
    "Sheffield": "E08000019",
    "Shepway": "E07000112",
    "Shetland Islands": "S12000027",
    "Shropshire": "E06000051",
    "Slough": "E06000039",
    "Solihull": "E08000029",
    "South Ayrshire": "S12000028",
    "South Bucks": "E07000006",
    "South Cambridgeshire": "E07000012",
    "South Derbyshire": "E07000039",
    "South Gloucestershire": "E06000025",
    "South Hams": "E07000044",
    "South Holland": "E07000140",
    "South Kesteven": "E07000141",
    "South Lakeland": "E07000031",
    "South Lanarkshire": "S12000029",
    "South Norfolk": "E07000149",
    "South Northamptonshire": "E07000155",
    "South Oxfordshire": "E07000179",
    "South Ribble": "E07000126",
    "South Somerset": "E07000189",
    "South Staffordshire": "E07000196",
    "South Tyneside": "E08000023",
    "Southampton": "E06000045",
    "Southend-on-Sea": "E06000033",
    "Southwark": "E09000028",
    "Spelthorne": "E07000213",
    "St Albans": "E07000240",
    "St Edmundsbury": "E07000204",
    "St. Helens": "E08000013",
    "Stafford": "E07000197",
    "Staffordshire Moorlands": "E07000198",
    "Stevenage": "E07000243",
    "Stirling": "S12000030",
    "Stockport": "E08000007",
    "Stockton-on-Tees": "E06000004",
    "Stoke-on-Trent": "E06000021",
    "Stratford-on-Avon": "E07000221",
    "Stroud": "E07000082",
    "Suffolk Coastal": "E07000205",
    "Sunderland": "E08000024",
    "Surrey Heath": "E07000214",
    "Sutton": "E09000029",
    "Swale": "E07000113",
    "Swansea": "W06000011",
    "Swindon": "E06000030",
    "Tameside": "E08000008",
    "Tamworth": "E07000199",
    "Tandridge": "E07000215",
    "Taunton Deane": "E07000190",
    "Teignbridge": "E07000045",
    "Telford and Wrekin": "E06000020",
    "Tendring": "E07000076",
    "Test Valley": "E07000093",
    "Tewkesbury": "E07000083",
    "Thanet": "E07000114",
    "Three Rivers": "E07000102",
    "Thurrock": "E06000034",
    "Tonbridge and Malling": "E07000115",
    "Torbay": "E06000027",
    "Torfaen": "W06000020",
    "Torridge": "E07000046",
    "Tower Hamlets": "E09000030",
    "Trafford": "E08000009",
    "Tunbridge Wells": "E07000116",
    "Uttlesford": "E07000077",
    "Vale of Glamorgan": "W06000014",
    "Vale of White Horse": "E07000180",
    "Wakefield": "E08000036",
    "Walsall": "E08000030",
    "Waltham Forest": "E09000031",
    "Wandsworth": "E09000032",
    "Warrington": "E06000007",
    "Warwick": "E07000222",
    "Watford": "E07000103",
    "Waveney": "E07000206",
    "Waverley": "E07000216",
    "Wealden": "E07000065",
    "Wellingborough": "E07000156",
    "Welwyn Hatfield": "E07000241",
    "West Berkshire": "E06000037",
    "West Devon": "E07000047",
    "West Dorset": "E07000052",
    "West Dunbartonshire": "S12000039",
    "West Lancashire": "E07000127",
    "West Lindsey": "E07000142",
    "West Lothian": "S12000040",
    "West Oxfordshire": "E07000181",
    "West Somerset": "E07000191",
    "Westminster": "E09000033",
    "Weymouth and Portland": "E07000053",
    "Wigan": "E08000010",
    "Wiltshire": "E06000054",
    "Winchester": "E07000094",
    "Windsor and Maidenhead": "E06000040",
    "Wirral": "E08000015",
    "Woking": "E07000217",
    "Wokingham": "E06000041",
    "Wolverhampton": "E08000031",
    "Worcester": "E07000237",
    "Worthing": "E07000229",
    "Wrexham": "W06000006",
    "Wychavon": "E07000238",
    "Wycombe": "E07000007",
    "Wyre": "E07000128",
    "Wyre Forest": "E07000239",
    "York": "E06000014"
}


export const allSSACodes = Object.values(subjectsMap).map(val => val[0]);
export const allSocs = Object.values(socToSubjectMap).reduce((prev, curr) => prev.concat(curr), []);
