import React, {useContext, useState, useMemo, useRef} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import UserContext from "../../../utils/context/user/userContext";
import { faChartLine, faHandshake, faLayerGroup, faPercent, faRuler, faStar } from "@fortawesome/free-solid-svg-icons";
import { allSocs, allSSACodes, socToSubjectMap, subjectsMap } from "../config/mappings";
import { FE_1, FE_2, FE_4 } from "./config/widgets";
import { Datasources, Endpoints } from "../../../config/api";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { useReactToPrint } from "react-to-print";
import { targetOptions, barsOptionsReverse, seriesOptions } from "../config/chartOptions";
import { filterYear } from "../config/dates";
import { jobPostingCurrentQuarterYear, jobPostingQuarterFilterYear, quarterMonthFilter } from "../../../utils/dates/datesUtils";
import { emptyFilter } from "../config/widgets";
import { buildCoreLMIFilter, buildJobPostingsFilter } from "../../../utils/filters/filters";
import { CounterWidget, PdfPrinter, PageBreak, SlidingPanel, SlidingPanelContent, SlidingPanelWidget, TargetChartWidget, Collapsible, SeriesValueWidget, BarChartWidget, GeneralSelector } from "@bge/global_solution_workbench_commons";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png"


const SSAObjects = Object.entries(subjectsMap).map( entry => {
  return {
    name: entry[0],
    code: entry[1],
    socs: socToSubjectMap[entry[0]]
  }
}) 

const SSAAnalysisDashboard = ({solutionId}) => {

  const componentRef = useRef();
  const userContext = useContext(UserContext);
  const locale = useContext(LanguageContext).locale;
  const [currentSSAInfo, setCurrentSSAInfo] = useState(SSAObjects[0]);
  
  const handlePrint = useReactToPrint(
    {
      documentTitle: "SSAAnalysisDashboard.pdf",
      content: () => componentRef.current
    }
  )

  // const aimsFilter = useMemo(() => buildCoreLMIFilter(FE_2.achievements.aimFilter, "Program", [[currentSSAInfo.name, currentSSAInfo.code]]), [currentSSAInfo]);
  // const aimsShareFilter = useMemo(() => buildCoreLMIFilter(FE_2.achievements.aimFilter, "Program", [[currentSSAInfo.name, currentSSAInfo.code], ["Total", allSSACodes]]), [currentSSAInfo]);
  const FTLEFilter = useMemo(() => buildCoreLMIFilter(FE_4.achievements.learnerFilter, "Program", [[currentSSAInfo.name, currentSSAInfo.code]]), [currentSSAInfo]);
  const FTLEShareFilter = useMemo(() => buildCoreLMIFilter(FE_4.achievements.learnerFilter, "Program", [[currentSSAInfo.name, currentSSAInfo.code], ["Total", allSSACodes]]), [currentSSAInfo]);
  const LQFilter = useMemo(() => buildCoreLMIFilter(FE_4.openingsFilter, "Occupation", [[currentSSAInfo.name, currentSSAInfo.socs], ["Total", allSocs]]), [currentSSAInfo]);

  const projectedOpeningsFilter = useMemo(() => buildCoreLMIFilter(FE_4.projectedOpenings.filter, "Occupation", [[currentSSAInfo.name, currentSSAInfo.socs]]), [currentSSAInfo]);

  const jobPostingsFilter = useMemo(() => buildJobPostingsFilter(FE_4.last12MonthsFilter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);
  const timeseriesFilter = useMemo(() => buildJobPostingsFilter(FE_4.timeseries.filter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);

  const top10IndustriesFilter = useMemo(() => buildCoreLMIFilter(FE_4.top10.industries.filter, "Occupation", [[currentSSAInfo.name, currentSSAInfo.socs]]), [currentSSAInfo]);
  const top10JobtTitlesFilter = useMemo(() => buildJobPostingsFilter(FE_4.top10.titles.filter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);
  const top10Companiesfilter = useMemo(() => buildJobPostingsFilter(FE_4.top10.company.filter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);
  const top10HardSkillsFilter = useMemo(() => buildJobPostingsFilter(FE_4.top10.hardSkills.filter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);
  const top10SoftSkillsFilter = useMemo(() => buildJobPostingsFilter(FE_4.top10.softSkills.filter, "soc4", currentSSAInfo.socs), [currentSSAInfo]);

  return (
    <div ref={componentRef}>
      <DashboardHeader 
        title = "SSA Analysis" 
        printer = {<PdfPrinter printHandler={handlePrint} />}
        contextWidget = {
          <SolutionContextWidget
            endpoint={Endpoints.RLS_INFO}
            datasource={Datasources.EMSI_API_UK}
            profileId={userContext.user.lastProfile}
            solutionId={solutionId}
            filter={emptyFilter}
          /> 
        }
      />
      <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xl={12} className="d-block mb-4 mb-md-0">
          <GeneralSelector
            current={currentSSAInfo.name}
            passedItems={SSAObjects}
            clickHandler={setCurrentSSAInfo}
            requestHandler={null}
            title="Choose Sector Subject Area"
            classSelected="ssa-selector"
            iconClass="fa-solid fa-caret-down"
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel>
            <SlidingPanelContent slidingPanelName="learnerAchievementsSlide">
              <div>
                <h3>Learner achievements</h3>
                <p>{FE_4.learnerTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                endpoint={buildFinalUrl(Endpoints.TOTALS, FE_2.achievements.facets)}
                datasource={Datasources.EMSI_API_UK}
                requestHandler={dataRequest}
                title="Learner achievements"
                subtitle={`FTLE achieved in ${filterYear}`}
                filter={FTLEFilter}
                profileId={userContext.user.lastProfile}
                solutionId={solutionId}
                icon={faStar}
                iconColor="shape-secondary"
                locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="marketShareTooltip">
            <SlidingPanelContent>
              <div>
                <h3>Learner market share</h3>
                <p>{FE_4.marketShareTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                endpoint={buildFinalUrl(Endpoints.RATES, FE_2.achievements.facets, {d: "Total", n: currentSSAInfo.name})}
                datasource={Datasources.EMSI_API_UK}
                requestHandler={dataRequest}
                title="Learner market share"
                subtitle={`Share of area FTLE achievements in ${filterYear}`}
                filter={FTLEShareFilter}
                profileId={userContext.user.lastProfile}
                solutionId={solutionId}
                icon={faPercent}
                iconColor="shape-secondary"
                isPercentage={true}
                decimalDigits={1}
                suffix="%"
                locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="locationQuotientSlide">
            <SlidingPanelContent >
              <div>
                <h3>Location Quotient</h3>
                <p>{FE_4.projectedOpenings.LQTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                endpoint={buildFinalUrl(Endpoints.QUOTIENTS, FE_4.projectedOpenings.facets)}
                datasource={Datasources.EMSI_API_UK}
                requestHandler={dataRequest}
                title="Location quotient"
                subtitle={`Location quotient in ${filterYear}`}
                filter={LQFilter}
                profileId={userContext.user.lastProfile}
                solutionId={solutionId}
                icon={faRuler}
                iconColor="shape-secondary"
                isPercentage={false}
                decimalDigits={2}
                locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="recruitmentDemandSlide">
            <SlidingPanelContent >
              <div>
                <h3>Recruitment demand</h3>
                <p>{FE_4.recruitmentDemandTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                endpoint={Endpoints.TOTALS}
                datasource={Datasources.EMSI_API_UK}
                requestHandler={dataRequest}
                title="Recruitment demand"
                subtitle={`12 months job postings in ${filterYear}`}
                filter={jobPostingsFilter}
                profileId={userContext.user.lastProfile}
                solutionId={solutionId}
                icon={faLayerGroup}
                iconColor="shape-secondary"
                locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="recruitmentGrowthSlide">
            <SlidingPanelContent>
              <div>
                <h3>Recruitment growth</h3>
                <p>{FE_1.recruitementGrowth.tooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.TRENDS, FE_1.recruitementGrowth.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Recruitment growth"
                  subtitle={`Postings growth in ${filterYear} year-on-year`}
                  filter={jobPostingsFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faChartLine}
                  iconColor="shape-secondary"
                  isPercentage={true}
                  decimalDigits={1}
                  suffix="%"
                  locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel slidingPanelName="annualOpeningsSlide">
            <SlidingPanelContent>
              <div>
                <h3>Annual openings</h3>
                <p>{FE_4.projectedOpenings.annualOpeningsTooltip}</p>
              </div>
            </SlidingPanelContent>
            <SlidingPanelWidget>
              <CounterWidget
                endpoint={buildFinalUrl(Endpoints.TOTALS, FE_4.projectedOpenings.facets)}
                datasource={Datasources.EMSI_API_UK}
                requestHandler={dataRequest}
                title="Annual openings"
                subtitle={`Projected average annual openings on ${filterYear}-${filterYear+9}`}
                filter={projectedOpeningsFilter}
                profileId={userContext.user.lastProfile}
                solutionId={solutionId}
                icon={faHandshake}
                iconColor="shape-secondary"
                isAverage={true}
                denominator={projectedOpeningsFilter["metrics"].length}
                decimalDigits={0}
                locale={locale}
              />
            </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Key Industries">
            <SlidingPanel slidingPanelName="industriesSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Key industries</h3>
                  <p>{FE_4.top10.industries.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <TargetChartWidget
                  endpoint={buildFinalUrl(Endpoints.TARGETS, FE_4.top10.industries.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Key industries"
                  subtitle={`Number of job postings in ${filterYear}`}
                  filter={top10IndustriesFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  chartClassName="ct-octave"
                  options={targetOptions}
                  showTitle={false}
                  locale={locale}
                />
              </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Recruitment demand">
            <SlidingPanel slidingPanelName="timeseriesSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Recruitment demand</h3>
                  <p>{FE_4.recruitmentDemandTooltip}</p>
                </div>
              </SlidingPanelContent>
                <SlidingPanelWidget>
                  <SeriesValueWidget
                    endpoint={Endpoints.TIMELINES}
                    datasource={Datasources.EMSI_API_UK}
                    requestHandler={dataRequest}
                    startYear={filterYear}
                    title="Recruitment demand"
                    subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                    filter={timeseriesFilter}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    showTitle={false}
                    options={seriesOptions}
                    locale={locale}
                    printWidget={true}
                  />
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Top 10 job titles">
            <SlidingPanel slidingPanelName="titlesSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Top 10 Job titles</h3>
                  <p>{FE_4.top10.titles.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <BarChartWidget
                  endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_4.top10.titles.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Top 10 job titles"
                  subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                  filter={top10JobtTitlesFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  chartClassName="ct-octave"
                  showTitle={false}
                  options={barsOptionsReverse}
                  locale={locale}
                  printWidget={true}
                />
              </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Top 10 companies">
            <SlidingPanel slidingPanelName="companiesSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Top 10 companies</h3>
                  <p>{FE_4.top10.company.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <BarChartWidget
                    endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_4.top10.company.facets)}
                    datasource={Datasources.EMSI_API_UK}
                    requestHandler={dataRequest}
                    title="Top 10 job companies"
                    subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                    filter={top10Companiesfilter}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    chartClassName="ct-octave"
                    showTitle={false}
                    options={barsOptionsReverse}
                    locale={locale}
                    printWidget={true}
                />
              </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Top 10 specialized skills">
            <SlidingPanel slidingPanelName="hardSkillsSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Top 10 specialized skills</h3>
                  <p>{FE_4.top10.hardSkills.tooltip}</p>
                </div>
              </SlidingPanelContent>
                <SlidingPanelWidget>
                    <BarChartWidget
                      endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_4.top10.hardSkills.facets)}
                      datasource={Datasources.EMSI_API_UK}
                      requestHandler={dataRequest}
                      title="Top 10 job specialized skills"
                      subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                      filter={top10HardSkillsFilter}
                      profileId={userContext.user.lastProfile}
                      solutionId={solutionId}
                      chartClassName="ct-octave"
                      showTitle={false}
                      options={barsOptionsReverse}
                      locale={locale}
                      printWidget={true}
                    />
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4">
          <Collapsible title="Top 10 common skills">
            <SlidingPanel slidingPanelName="softSkillsSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Top 10 common skills</h3>
                  <p>{FE_4.top10.softSkills.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <BarChartWidget
                  endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_4.top10.softSkills.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Top 10 job common skills"
                  subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                  filter={top10SoftSkillsFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  chartClassName="ct-octave"
                  showTitle={false}
                  options={barsOptionsReverse}
                  locale={locale}
                  printWidget={true}
                />
              </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row> 
    </div>
  );
};

export default SSAAnalysisDashboard;