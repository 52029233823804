import { useContext } from "react"
import UserContext from "../../../utils/context/user/userContext"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function updateWorkbook(filters) {
    if (filters) {
        setTimeout(async() => {
            const workbook = document.getElementById("tableauViz").workbook;
            if (workbook._workbookImpl) {
                await setVizParam(workbook, filters);
            } else {
                updateWorkbook(filters);
            }
        }, 500);
    }
}


async function setVizParam(workbook, filters) {
    try {
        const {country, company, sector} = filters;
        if (country && company && sector){
            await workbook.changeParameterValueAsync("Country Parameter", country); 
            await workbook.changeParameterValueAsync("Parametro Company", company); 
            await workbook.changeParameterValueAsync("Sector Paramter", sector);
        }
    } catch (error) {
        console.log (error);
    };
}


const WorkforceIndex = () => {

    const filters = useContext(UserContext).user.customFilters?.tableau;

    updateWorkbook(filters);

    return (
        <Row className="justify-content-md-center">
            <Col xs={12} className="mb-4 d-none d-sm-block">
                <div id="main" >
                    <tableau-viz
                        id="tableauViz"
                        src="https://public.tableau.com/views/WorkForce_Index_v1/HomePage"
                        toolbar="hidden" hide-tabs
                   />
                </div>
            </Col>
        </Row>
    )
}

export default WorkforceIndex;