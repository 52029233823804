import { currentYear, filterYear } from "../../pages/dashboard/config/dates";

export const timePeriods = [
  {name: "Past day", days: 1},
  {name: "Past week", days: 7},
  {name: "Past 2 weeks", days: 14},
  {name: "Past month", days: 30},
  {name: "Past 3 months", days: 90},
  {name: "Past Year", days: 365}
];

export const monthMapping = {
  "01": "Jan.",
  "02": "Feb.",
  "03": "Mar.",
  "04": "Apr.",
  "05": "May",
  "06": "Jun.",
  "07": "Jul.",
  "08": "Aug.",
  "09": "Sep.",
  "10": "Oct.",
  "11": "Nov.",
  "12": "Dec."
};

function adjustMonth (monthNum, mod) {
  let val = Math.floor((monthNum-1)/mod)*mod;
  return val > 0 ? val : 12;
};


function getMonthString(month){
  const monthStr = `0${month}`;
  return monthStr.length > 2 ? monthStr.substring(1) : monthStr;
};

export function adjustYear (year, monthNum){
  return monthNum === 12 ? year-1 : year;
}

const curMonth = new Date().getMonth()+1;

export const prevMonthNum = adjustMonth(curMonth, 1);
export const prevMonthFilter = getMonthString(prevMonthNum);
export const jobPostingCurrentYear = adjustYear(currentYear, prevMonthNum);
export const jobPostingFilterYear = adjustYear(filterYear, prevMonthNum);

export const quarterMonthNum = adjustMonth(curMonth, 3);
export const quarterMonthFilter = getMonthString(quarterMonthNum);
export const jobPostingCurrentQuarterYear = adjustYear(currentYear, quarterMonthNum);
export const jobPostingQuarterFilterYear = adjustYear(filterYear, quarterMonthNum);

