
import React from "react";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Mark_Color.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";

const DashboardOverview = () => {

  return (
    <section className="section-header overflow-hidden pt-3 pt-lg-5 d-flex align-items-stretch">
      <Container>
        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-2">
          <Col xs={12} className="text-center">
            <div className="react-big-icon d-none d-lg-block">
              <FontAwesomeIcon icon={faChartPie} />
            </div>
            <h1 className="fw-bolder text-secondary-lc mt-5 mb-5 text-center">Welcome to Global Solution Workbench</h1>
            <p className="text-muted text-dark-lc fw-light mb-5 h5">... where you can find your dashboards</p>
            <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-8">
              <div className="text-center">
                <a href="https://lightcast.io" target="_blank" rel="noopener noreferrer">
                  <Image src={CompanyLogo} width={200} className="mt-3" alt="Emsi-BurningGlass" />
                  <p className="text-muted text-gray-lc-600 font-small logoSubtitle m-0">A Lightcast solution</p>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DashboardOverview;