import React, { useReducer } from "react";
import LanguageContext from "./languageContext";
import * as ACTIONS from "../../actions/actions";
import * as LangReducer from "../../reducers/langReducer";

const LanguageContextState = ({children}) => {

    const [stateLangReducer, dispatchLangReducer] = useReducer(
        LangReducer.LangReducer,
        LangReducer.initialState
    );

    const changeLanguage = (data) => {
        dispatchLangReducer(ACTIONS.changeLang(data))
    };

    return (
        <LanguageContext.Provider 
            value={{ 
                lang: stateLangReducer.lang,
                locale: stateLangReducer.locale,
                flag: stateLangReducer.flag,
                changeLang: (data) => changeLanguage(data)
            }} 
        >
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContextState;