import { ROOT_DATA_API, ROOT_UMT_API } from "./env";

export const Endpoints = {
    LOGIN: `${ROOT_UMT_API}/api/users/login`,
    PASSCODE: `${ROOT_UMT_API}/api/passcodes`,
    LOGGING: `${ROOT_UMT_API}/api/logs`,
    TOTALS: `${ROOT_DATA_API}/api/data/totals`,
    TIMELINES: `${ROOT_DATA_API}/api/data/timelines`,
    RANKINGS: `${ROOT_DATA_API}/api/data/rankings`,
    TARGETS: `${ROOT_DATA_API}/api/data/targets`,
    TRENDS: `${ROOT_DATA_API}/api/data/trends`,
    GAP_ANALYSIS: `${ROOT_DATA_API}/api/data/gap-analysis`,
    RATES: `${ROOT_DATA_API}/api/data/rates`,
    QUOTIENTS: `${ROOT_DATA_API}/api/data/quotients`,
    REFRESH: `${ROOT_UMT_API}/api/jwt/refresh`,
    USER_INFO: `${ROOT_UMT_API}/api/jwt/user`,
    USER_PROFILE: `${ROOT_UMT_API}/api/users/`,
    USER_UPDATE_ON_LOGOUT: `${ROOT_UMT_API}/api/users/{id}/last`,
    RESET_PASSWORD: `${ROOT_UMT_API}/api/passwords/reset/request`,
    CONFIRM_RESET_PASSWORD: `${ROOT_UMT_API}/api/passwords/reset/confirm`,
    RLS_INFO: `${ROOT_UMT_API}/api/rls/info`
};


export const Datasources = {
    EMSI_API_UK: "uk-jpa",
    EMSI_API_GLOBAL: "global-postings",
    EU_DATA: "EU_DATA"
}