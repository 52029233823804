
export const AppRoutes = {
    // solutions
    Dashboards_template: { path: "/dashboard/" },
    // pages
    Presentation: { path: "/" },
    UserProfile: {path: "/profile"},
    DashboardOverview: { path: "/dashboard/overview" },
    Signin: { path: "/public/sign-in" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    NotFound: { path: "/public/404" },
    ServerError: { path: "/public/500" },
};