import React, {useContext, useRef} from "react";
import { faChartLine, faLayerGroup, faPencilRuler, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { buildFinalUrl, dataRequest } from "../../../utils/request/api";
import UserContext from "../../../utils/context/user/userContext";
import { FE_1 } from "./config/widgets";
import { Datasources, Endpoints } from "../../../config/api";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import SolutionContextWidget from "../../../components/Widgets/SolutionContextWidget/SolutionContextWidget";
import { useReactToPrint } from "react-to-print";
import { targetOptions, barsOptionsReverse, seriesOptions } from "../config/chartOptions";
import { filterYear } from "../config/dates";
import { emptyFilter } from "../config/widgets";
import { jobPostingCurrentQuarterYear, jobPostingQuarterFilterYear, quarterMonthFilter } from "../../../utils/dates/datesUtils";
import { BarChartWidget, Collapsible, CounterWidget, PageBreak, PdfPrinter, SeriesValueWidget, TargetChartWidget, SlidingPanel, SlidingPanelContent, SlidingPanelWidget } from "@bge/global_solution_workbench_commons";
import LanguageContext from "../../../utils/context/i18n/languageContext";
import CompanyLogo from "../../../assets/img/Lighcast_RGB_Lockup_Color.png";

const CILocalEconomyDashboard = ({solutionId}) => {

  const componentRef = useRef();
  const userContext = useContext(UserContext);
  const locale = useContext(LanguageContext).locale

  const handlePrint = useReactToPrint(
    {
      documentTitle: "CollegeInsightLocalEconomyDashboard.pdf",
      content: () => componentRef.current
    }
  )
  
  return (
    <div ref={componentRef}>
      <DashboardHeader 
        title = "Local Economy"
        printer = {<PdfPrinter printHandler={handlePrint} />}
        contextWidget = { 
          <SolutionContextWidget
            endpoint={Endpoints.RLS_INFO}
            datasource={Datasources.EMSI_API_UK}
            profileId={userContext.user.lastProfile}
            solutionId={solutionId}
            filter={emptyFilter}
          />
        }
      />
      <Row className="justify-content-md-center">
       <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel
            slidingPanelName="recruiterDemandSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Recruitment demand</h3>
                  <p>{FE_1.recruitmentDemandTooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <CounterWidget
                  endpoint={Endpoints.TOTALS}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Recruitment demand"
                  subtitle={`12 month unique postings in ${filterYear}`}
                  filter={FE_1.last12MonthsFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faLayerGroup}
                  iconColor="shape-secondary"
                  locale={locale}
                />
              </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel
            slidingPanelName="recruiterGrowthSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Recruitment growth</h3>
                  <p>{FE_1.recruitementGrowth.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.TRENDS, FE_1.recruitementGrowth.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  title="Recruitment growth"
                  subtitle={`Postings growth in ${filterYear} year-on-year`}
                  locale={locale}
                  filter={FE_1.last12MonthsFilter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faChartLine}
                  iconColor="shape-secondary"
                  isPercentage={true}
                  decimalDigits={2}
                  suffix="%"
                />
              </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel
            slidingPanelName="totalJobsSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Employment</h3>
                  <p>{FE_1.totalJobs.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.TOTALS, FE_1.totalJobs.facets)}
                  datasource={Datasources.EMSI_API_UK}
                  title="Employment"
                  subtitle={`Jobs in ${filterYear}`}
                  requestHandler={dataRequest}
                  filter={FE_1.totalJobs.filter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faUsersGear}
                  iconColor="shape-secondary"
                  isPercentage={false}
                  decimalDigits={0}
                  locale={locale}
                />
              </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
        <Col xs={12} sm={6} xl={3} className="mb-2">
          <SlidingPanel
            slidingPanelName="unemploymentSlide">
              <SlidingPanelContent>
                <div>
                  <h3>Unemployment</h3>
                  <p>{FE_1.unemployment.tooltip}</p>
                </div>
              </SlidingPanelContent>
              <SlidingPanelWidget>
                <CounterWidget
                  endpoint={buildFinalUrl(Endpoints.RATES, FE_1.unemployment.facets, FE_1.unemployment.queries)}
                  datasource={Datasources.EMSI_API_UK}
                  title="Unemployment"
                  subtitle={`Unemployment rate in ${filterYear}`}
                  requestHandler={dataRequest}
                  filter={FE_1.unemployment.filter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  icon={faPencilRuler}
                  iconColor="shape-secondary"
                  suffix="%"
                  isPercentage={true}
                  decimalDigits={1}
                  locale={locale}
                />
              </SlidingPanelWidget>
          </SlidingPanel>
        </Col>
      </Row>
      <Row className="justify-content-md-center industryCompCell">
        <Col xs={12} className="mb-4">
          <Collapsible title="Industry composition">
            <SlidingPanel
              slidingPanelName="industryCompositionSlide">
                <SlidingPanelContent>
                  <div>
                    <h1>Industry composition</h1>
                    <p>{FE_1.industryComposition.tooltip}</p>
                  </div>
                </SlidingPanelContent>
                <SlidingPanelWidget>
                  <TargetChartWidget
                    endpoint={buildFinalUrl(Endpoints.TARGETS, FE_1.industryComposition.facets)}
                    datasource={Datasources.EMSI_API_UK}
                    requestHandler={dataRequest}
                    title="Industry composition"
                    subtitle={`Jobs in ${filterYear} and UK equivalent`}
                    filter={FE_1.industryComposition.filter}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    chartClassName="ct-square industryCompCell"
                    options={targetOptions}
                    showTitle={false}
                    locale={locale}
                  />
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center jobPostCell">
        <Col xs={12} className="mb-4 jobPostCell">
          <Collapsible title="Job Postings">
            <SlidingPanel
              slidingPanelName="timeSeriesSlide">
                <SlidingPanelContent>
                  <div>
                    <h1>Job Postings</h1>
                    <p>{FE_1.timeseries.tooltip}</p>
                  </div>
                </SlidingPanelContent>
                <SlidingPanelWidget className="jobPostCell">
                <SeriesValueWidget
                  endpoint={Endpoints.TIMELINES}
                  datasource={Datasources.EMSI_API_UK}
                  requestHandler={dataRequest}
                  startYear={filterYear}
                  title="Job Postings"
                  subtitle={`Unique active postings per month in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                  filter={FE_1.timeseries.filter}
                  profileId={userContext.user.lastProfile}
                  solutionId={solutionId}
                  chartClassName="ct-series-a ct-double-octave"
                  options={seriesOptions}
                  showTitle={false}
                  locale={locale}
                  printWidget={true}
                />
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center jobPostCell">
        <Col xs={12} className="mb-4 jobPostCell">
          <Collapsible title="Top 10 companies">
            <SlidingPanel
              slidingPanelName="top10CompaniesSlide">
                <SlidingPanelContent>
                  <div>
                    <h1>Top 10 companies</h1>
                    <p>{FE_1.top10.company.tooltip}</p>
                  </div>
                </SlidingPanelContent>
                <SlidingPanelWidget>
                  <BarChartWidget
                    endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_1.top10.company.facets)}
                    datasource={Datasources.EMSI_API_UK}
                    requestHandler={dataRequest}
                    title="Top 10 companies"
                    subtitle={`Unique active postings in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                    filter={FE_1.top10.company.filter}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    chartClassName="ct-octave jobPostCell"
                    options={barsOptionsReverse}
                    locale={locale}
                    showTitle={false}
                    printWidget={true}
                  />
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
      <PageBreak logo={CompanyLogo} alt="Lightcast"/>
      <Row className="justify-content-md-center jobPostCell">
        <Col xs={12} className="mb-4 jobPostCell">
          <Collapsible title="Top 10 occupations">
            <SlidingPanel
              slidingPanelName="top10OccupationsSlide">
                <SlidingPanelContent>
                  <div>
                    <h1>Top 10 occupations</h1>
                    <p>{FE_1.top10.occupation.tooltip}</p>
                  </div>
                </SlidingPanelContent>
                <SlidingPanelWidget>
                  <BarChartWidget
                    endpoint={buildFinalUrl(Endpoints.RANKINGS, FE_1.top10.occupation.facets)}
                    datasource={Datasources.EMSI_API_UK}
                    requestHandler={dataRequest}
                    title="Top 10 occupations"
                    subtitle={`Unique active postings in the period ${jobPostingQuarterFilterYear}/${quarterMonthFilter} - ${jobPostingCurrentQuarterYear}/${quarterMonthFilter}`}
                    filter={FE_1.top10.occupation.filter}
                    profileId={userContext.user.lastProfile}
                    solutionId={solutionId}
                    chartClassName="ct-octave jobPostCell"
                    options={barsOptionsReverse}
                    locale={locale}
                    showTitle={false}
                    printWidget={true}
                  />                
                </SlidingPanelWidget>
            </SlidingPanel>
          </Collapsible>
        </Col>
      </Row>
    </div>
  );
};

export default CILocalEconomyDashboard;