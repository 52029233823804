import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import React from "react";
import CompanyLogo from "../../assets/img/Lighcast_RGB_Lockup_Color.png"

const DashboardHeader = (props) => {

  return (
    <>
        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-2">
            <Col xs={12} className="d-sm-block d-md-none text-center mt-3 mb-3 mb-md-0 d-print-none">
                <a href="https://lightcast.io" target="_blank" rel="noopener noreferrer">
                    <Image src={CompanyLogo} width={200} alt="Lightcast" />
                </a>
            </Col>
            <Col xs={12} md={8} lg={9} xl={10} className="d-block mb-4 mb-md-0">
                <h1 className="h1 text-secondary-lc">{props.title}</h1>
            </Col>
            <Col md={4} lg={3} xl={2} className="d-none d-md-block d-sm-none mb-4 mb-md-0 d-print-block">
                <a href="https://lightcast.io" className="align-items-right" target="_blank" rel="noopener noreferrer">
                  <Image src={CompanyLogo} alt="Lightcast" />
                </a>
            </Col>
        </Row>
        <Row className="">
          <Col xs={10} lg={11} className="d-block mb-2 p-1 text-dark-lc">
            {props.contextWidget}
          </Col>
          <Col xs={2} lg={1} className="d-block mb-2 p-1 align-items-right">
            {props.printer}
          </Col>
      </Row>
    </>
    );
}

export default DashboardHeader;