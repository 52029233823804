import React, {useState, useEffect} from "react";
import { Outlet} from "react-router-dom";
import Preloader from "../../components/Preloader";

const PublicLoader = () => {
    const [loaded, setLoaded] = useState(false);
    
  
    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);
  
    return (
      // <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
      loaded ? <Outlet />: <Preloader show={loaded ? false : true} />
    );
};

export default PublicLoader;